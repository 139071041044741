
@media all and (min-width:0px) and (max-width:768px){
    #Fullpage .width_con .three_con li {
        width: calc(33.3333% - 20px);
        margin: 0 10px;
    }

}
@media all and (min-width:769px) and (max-width:1024px){
    #Fullpage .width_con .three_con li {
        width: calc(33.3333% - 10px);
        margin: 0 5px;
    }
    /* .sections{padding:0;}
    header{margin:0;} */
}
@media all and (min-width:1025px) and (max-width:1280px){
    #Fullpage .width_con .three_con li {
        width: calc(33.3333% - 15px);
        margin: 0 7.5px;
    }
}
@media all and (min-width:1281px) and (max-width:1561px){
    #Fullpage .width_con{width:calc(100% - 200px); margin:0 100px;}
    #Fullpage .width_con .three_con li {
        width: calc(33.3333% - 25px);
        margin: 0 12.5px;
    }

}
@media all and (min-width:1561px) and (max-width:1920px){
    /* .sections{padding:0;}
    header{margin:0;} */
}
@media all and (min-width:0px) and (max-width:1460px){
    #Fullpage .width_con{width:calc(100% - 200px); margin:0 100px;}
}

@media screen and (max-width:1200px){
    
    #Fullpage .width_con .wrap1_info li .desc h3,
    #Fullpage .width_con .wrap1_info li .desc:hover h3 {
        font-size: 16px;
    }

}

@media screen and (max-width:1350px){

    #Fullpage h2 {
        font-size: 18px;
    }
    #Fullpage h4 {
        font-size: 16px;
    }

}