/* -----------------------------------------------------tablet----------------------------------------------------- */
@media all and (max-width:1024px){

    #Mappage .cmprsEstmt .allow_building_btn_con {
        top: 3px;
        right: 1%;
    }
    #Mappage .cmprsEstmt .not_allow {
        margin: 50px 0;
    }
    #Mappage .informationWrap.close,
    #Mappage .consulting_con.close {
        height: 8%;
        padding: 0;
    }
    #Mappage .informationWrap .btn_b.close,
    #Mappage .informationWrap .noneBox.close,
    #Mappage #bookmark.close,
    #Mappage .informationWrap .loadingModule.close,
    #Mappage .consultingForm.close,
    #Mappage .cmprsEstmt.close {
        display: none;
    }
    #Mappage .M_side_menu {
        display: block !important;
    }
    #Mappage .side_menu {
        display: none;
    }
    #Mappage .cmprsEstmt .address_info_wrap {
        flex-flow: column nowrap;
    }
    #Mappage .my_page, #Mappage .book_mark {
        display: none;
    }
    #Mappage .header .mobile_mypage {
        display: block !important;
        height: 90%;
        float: right;
        margin: 0;
        padding: 5px;
    }
    #Mappage .header .mobile_mypage img {
        height: 100%;
    }
    #Mappage .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 95%;
        left: 50%;
        top: 1%;
        transform: translateX(-50%);
        border-radius: 1rem;
        height: 3rem;
        padding: 6px;
        background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgb(130, 130, 130) 100%);
        box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.4);
    }
    #Mappage .header .contentWrap .imageBox {
        margin: 0;
        height: auto;
    }
    #Mappage .header .contentWrap {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    #Mappage .header .contentWrap .inputBox input {
        padding: 0 2.8rem 0 1rem;
        font-size: 1rem;
    }
    #Mappage .header .contentWrap .imageBox img.logo {
        margin: 0;
        height: auto;
    }
    #Mappage .header .contentWrap .inputBox img.search {
        right: 9px;
        top: 50%;
        transform: translateY(-50%);
        height: 20px;
    }
    #Mappage .header .contentWrap .inputBox {
        width: 100%;
        border-radius: 8px;
        margin: 0 5px;
    }
    #Mappage .informationWrap {
        left: 0; bottom: 0;
        top: auto;
        width: 100%; height:400px;
        padding:20px 20px 0 20px;
        min-height: auto;
    }
    #Mappage .informationWrap .book_mark{
        right:20px; top:40px;
        z-index:2;
        transition:all 100ms ease-out;
    }
    #Mappage .informationWrap .noneBox.sticky .book_mark{
        top:35px;
    }
    #Mappage .modal {
        text-align: center;
        width: 300px;
        z-index: 999;
        padding: 20px 0;
        bottom: 50%;
    }
    #Mappage .mobile_nav {
        display: flex;
        position: absolute;
        right: 2.5%;
        top: 60px;
        
    }
    #Mappage .mobile_bookmark {
        display: flex !important;
        box-shadow: 2px 2px 4px 0 rgba(0,0,0,0.25);
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        z-index: 99;
        background-color: #fff;
        border-radius: 5px;
        box-shadow: 2px 2px 4px 0 rgba(0,0,0,0.25);
        margin-right: 5px;
    }
    #Mappage .mobile_bookmark img {
        height: 70%;
    }
    #Mappage .mobile_menu_dot {
        display: flex !important;
        width: 40px;
        height: 40px;
        z-index: 99;
        background-color: #fff;
        border-radius: 5px;
        box-shadow: 2px 2px 4px 0 rgba(0,0,0,0.25);
        justify-content: center;
        align-items: center;
    }
    #Mappage .mobile_menu_dot .mobile_menu {
        height: 70%;
        line-height: 40px;
    }
    #Mappage .side_menu {
        max-height: 302px;
        right: 2.5%;
        top: 105px;
    }
    #Mappage .side_menu .custom_menu_wrap, #Mappage .side_menu .zoom_menu_wrap {
        border-radius: 5px;
    }
    #Mappage .side_menu .zoom_menu_wrap{
        display:none;
    }
    #Mappage .informationWrap .btn_b {
        position:fixed;
        right:20px; bottom:20px;
        padding: 7px 25px 7px 5px;
        backdrop-filter:blur(5px);
        z-index: 9999;
    }
    #Mappage .informationWrap .btn_b:after,
    #Mappage .informationWrap .btn_b:before{
        background:#7c259b;
    }
    #Mappage .informationWrap .btn_b.black {
        bottom:40px;
        padding: 7px 25px 7px 5px;
        backdrop-filter:none;
        z-index: 9999;
    }
    #Mappage .informationWrap .btn_b.black:after,
    #Mappage .informationWrap .btn_b.black:before{
        background:#000;
    }

    #Mappage .informationWrap .btn_b:hover:after,
    #Mappage .informationWrap .btn_b:hover:before{margin-right:0;}
    .footer {
        display: none !important;
    }
    #Mappage .cmprsEstmt {
        height: 75% !important;
        z-index: 9;
        border: none;
        padding: 20px 0 50px;
    }
    #Mappage .cmprsEstmt.close {
        height: 8% !important;
    }
    #Mappage .tabContent.bookmarks:after {
        display: none;
    }
    #Mappage .cmprsEstmt .table_head {
        display: none;
    }
    #Mappage .cmprsEstmt .all_info {
        display: flex;
        align-items: end;
    }
    #Mappage .cmprsEstmt .info_title_M {
        width: 48%;
        height: 100%;
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-between;
        text-align: end;
    }
    #Mappage .cmprsEstmt .info_title_M > p {
        font-weight: 700;
        padding: 0 50px 0 0;
        height: 28px;
        line-height: 28px;
        color: #737373;
        border-bottom: 1px dashed #000;
        min-width: 70px;
    }
    #Mappage .cmprsEstmt .info_title_M > p:last-child {
        border: 0;
    }
    #Mappage .cmprsEstmt .address_info {
        width: 52%;
    }
    #Mappage .cmprsEstmt .info {
        width: 100%;
        overflow: scroll;
        border: none;
    }
    #Mappage .consulting_con {
        z-index: 4;
        height: auto;
    }
    #Mappage .consulting_con .width_con {
        height: auto;
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        padding: 40px 0 80px;
    }
    .consulting_con .left_con , .consulting_con .right_con{
        width: 70%;
        margin:0 50px;
    }

    .consulting_con .left_con:after{
        background:transparent;
    }
    #Mappage .consulting_con .submit_btn {
        width: 200px;
        height: 30px;
        font-size: 16px;
        padding: 0;
        line-height: 30px;
    }
    #Mappage .consulting_con .consulting_detail_input, .consulting_con .consulting_select, .consulting_con .consulting_input {
        padding: 5px 12px;
    }
    #Mappage .consulting_con .consulting_detail_input {
        padding: 12px;
    }
    .consulting_con .consulting_select_title {
        padding: 7px 0;
    }
    #Mappage .informationWrap .M_infoBtn_wrap{
        position:absolute;
        width:100%; height:40px;
        left:0; top:-6px;
        z-index:2;
    }
    #Mappage .informationWrap .M_infoBtn {
    position: absolute;
    width: 30%; height: 20px;
    line-height: 30px;
    left: 50%; top:0;
    transform: translateX(-50%);
    background-color: #fff;
    border-radius: 100px;
    text-align: center;
    }
    #Mappage .informationWrap .infoBtn_line {
        background-color: #aaa;
        width: 50%;
        height: 2px;
        margin: 0 auto;
        margin-top: 9px;
    }
    #Mappage .info_bookmark {
        display: block;
    }
    #Mappage .cmprsEstmt .bookmark {
        margin: 0;
        margin-top: 30px;
    }
    .input_cover:nth-of-type(2) {
        height: 100px;
    }
    #Mappage .cmprsEstmt .address_info .content {
        text-align: left;
        padding: 0 0 0 50px;
    }
    

/* -----------------------------------------------------addBookMark(tablet,mobile)----------------------------------------------------- */
    #Mappage .bookmark_window,
    #Mappage .subcategory_window{
        position:fixed;
        left:50%; top:50%;
        transform:translate(-50%,-50%);
        width:80vw; height:450px;
        min-width:260px;
        border:none;
        border-radius:10px;
        border:solid 1px #434343;
        z-index:999999;
    }
    #Mappage .bookmark_window.hidden,
    #Mappage .subcategory_window.hidden{
        opacity:0;
        transform:translate(-50%,-45%);
        pointer-events: none;
        border:none;
    }

    #Mappage .bookmark_window .content_wrap{
        height:384px;
    }

    #Mappage .bookmark_window_cover{
        position:fixed;
        width:100vw; height:100vh;
        left:0; top:0;
        background:rgba(0,0,0,0.3);
        backdrop-filter:blur(1px);
        z-index:99999;
    }
    #Mappage .bookmark_window_cover.hidden{
        opacity:0;
        pointer-events: none;
        border:none;

    }
    #Mappage .sub_category_list .title{
        width:120px;

    }
    #Mappage .subcategory_window .content_wrap{
        height: 320px;
    }

/* -----------------------------------------------------information(tablet,mobile)----------------------------------------------------- */


    #Mappage .informationWrap .infoBox .tabContent {
        height: auto;
        padding: 30px 5px 30px 5px;
        border:none;
    }
    #Mappage .informationWrap .noneBox.sticky .infoBox .tabContent{
        margin-top:120px;
    }
    
    #Mappage .informationWrap .infoBox .tabContent .content_wrap{
        margin-bottom:60px;
    }
    #Mappage  .informationWrap .noneBox{
        position:static;
        height: 100%;
        overflow-y:scroll;
    }
    #Mappage  .informationWrap .noneBox::-webkit-scrollbar {
        display:none;
    }
    #Mappage .informationWrap .infoBox .tabMenuUl{
        position:relative;
        display:flex;
        justify-content: space-between;
        border-top:solid 1px #000;
        border-bottom:solid 1px #ddd;
        padding:6px 0;
        background:#fff;
        z-index:1;
    }
    #Mappage .informationWrap .noneBox.sticky .infoBox .tabMenuUl{
        position:absolute;
        top:65px;
        width:calc(100% - 40px);
    }
    #Mappage .informationWrap .infoBox:before{
        position:absolute;
        left:0; top:10px;
        width:100%; height:140px;
        margin-bottom:1px;
        content:'';
        background:#fff;
        z-index:1;
    }
    #Mappage .informationWrap .infoBox.cover_hidden:before{
        display:none;
    }
    #Mappage .informationWrap .infoBox .tabMenuUl .tabMenu{
        margin-top:0; padding:6px 0;
        font-size:14px;
        border-radius:5px;
        border:none;
        background:#fff;
    }
    #Mappage .informationWrap .infoBox .tabMenuUl .tabMenu.active{
        padding:6px 0;
        font-size:16px; font-weight:800;
        border:none;
        background:#eee;
    }
    #Mappage .informationWrap .infoBox .tabMenuUl .tabMenu.active:after,
    #Mappage .informationWrap .infoBox .tabMenuUl .tabMenu.active:before{
        display:none;

    }
    #Mappage .informationWrap .titleBox{
        position:relative;
        margin-top:20px;
        z-index:2;
    }

    #Mappage .informationWrap .subTitleBox{
        position:relative;
        z-index:3;
    }
    #Mappage .informationWrap .noneBox.sticky .subTitleBox{
        position:absolute;
        top:20px ;
    }

    #Mappage .informationWrap .infoBox .tabContent .content_wrap .content_box.totalPrice{
        position: absolute;
        bottom: -66px;
        margin:0;
        padding:17px 0;
        border-top:dashed 1px 
    }
    #Mappage .informationWrap .infoBox .tabContent .content_wrap .content_box.totalPrice:after{
        position:absolute;
        left:76px; top:15px;
        width:1px; height:calc(100% - 27px);
        content:'';
        background:rgba(17,17,17,.2);
    }
    #Mappage .informationWrap .infoBox .tabContent .content_wrap .content_box .title{
        font-size:12px;
    }
    #Mappage .informationWrap .infoBox .tabContent .content_wrap .content_box .content{
        font-size:15px;
    }
    #Mappage .informationWrap .infoBox .tabContent .content_wrap.tab_04{
        margin-bottom:80px;
    }
    #Mappage .informationWrap .infoBox .tabContent .content_wrap .content_box.mobile_hide{
        display:none;
    }

    #Mappage .informationWrap .noneBox.sticky .infoBox .tabContent .content_wrap .content_box.allowBuilding{
        
    }
    #Mappage .informationWrap .infoBox .tabContent .content_wrap .content_box.allowBuilding{
        position:absolute;
        top:-50px;
        margin:0;
    }
    #Mappage .more_btn{
        width:50px;
        padding:0 8px;
    }
    #Mappage .more_btn:after,
    #Mappage .more_btn:before{
        display:none;
    }
    #Mappage .informationWrap .infoBox .mobile_allowBuilding{
        display:block;
        position:relative;
        width:100%;
        margin-top:20px; padding:0 5px;
        z-index:1;

    }
    #Mappage .informationWrap .infoBox .mobile_allowBuilding .title{
        display: inline-block;
        width: 67px;
        margin: 0 21px 0 0;
        text-align: right;
        font-size: 12px;
        font-weight: 600;
        line-height: 1em;
        margin-top: 3px;
        vertical-align: top;
        color: #737373;
    }
    #Mappage .informationWrap .infoBox .mobile_allowBuilding .allowBuildingList{
        position: relative;
        width: 100%;
        padding: 0 5px;
        height: 24px;
        font-size: 14px;
        font-weight: 800;
        border-radius: 5px;
        box-shadow: 0px 1px 1px #000;        
    }
    #Mappage .informationWrap .infoBox .mobile_allowBuilding .content{
        display:inline-block;
        width:calc(100% - 144px);
        font-size:15px;
        color:#111;
        font-weight:800;
    }
    #Mappage .informationWrap .noneBox.sticky .mobile_allowBuilding{
        position:absolute;
        top:105px;
        width:90%;
    }
}

/* -----------------------------------------------------mobile----------------------------------------------------- */

@media all and (max-width:768px){

    .consulting_con .left_con , .consulting_con .right_con{
        width: 90%;
        margin: 0;
    }

    

}
@media all and (max-width:400px){
    #Mappage .bookmark_window,
    #Mappage .subcategory_window{
        max-width:280px;
    }

    #Mappage .cmprsEstmt .address_info {
        width: 100%;
    }
    #Mappage .cmprsEstmt .info_title_M p {
        padding: 0;
    }
    #Mappage .cmprsEstmt .all_info {
        justify-content: space-between;
    }
}

@media all and (max-width:360px){
    #Mappage .more_btn{
        width:56px;
    }
    #Mappage .more_btn:after,
    #Mappage .more_btn:before{
        content:none;
    }
}