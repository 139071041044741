#joinagree {
    padding: 0 5%;
}
#joinagree main .agree_all {}
#joinagree main .agree_all .list_tab {
    margin: 100px auto;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    max-width: 700px;
}
#joinagree main .agree_all .list_tab li {
}
#joinagree main .agree_all .list_tab li a {
    text-align: center;
    display: block;
    width: 150px;
    height: 50px;
    line-height: 50px;
    border: 1px solid #babcbe;
    border-radius: 20px;
    color: #babcbe;
}
#joinagree main .agree_all .list_tab li a.active {
    color: #AB35D5;
    font-weight: 800;
    border: 1px solid #AB35D5;
    box-shadow: 0 0 3px 1px #AB35D5;
}
#joinagree main .agree_all .agree_contents li.active {
    display: flex;
}
#joinagree main .agree_all .agree_contents {}
#joinagree main .agree_all .agree_contents li {
    display: none;
    margin: 0 auto;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    max-width: 800px;
}
#joinagree main .agree_all .agree_contents li img {
    width: 100%;
}


@media screen and (max-width:1024px) {

    #joinagree main .agree_all .list_tab {
        flex-flow: column nowrap;
        margin: 80px auto 10px;
    }
    #joinagree main .agree_all .list_tab li a {
        width: 70vw;
        height: 40px;
        line-height: 40px;
    }
    #joinagree main .agree_all .list_tab li {
        margin-bottom: 10px;
    }

}