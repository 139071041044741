#mypage {
    margin: 0 5%;
}
#mypage main {
    display: flex; justify-content: center;align-items: center;
    height: calc(100vh - 13.714rem);
}
#mypage input {width: 100%;}
#mypage input::placeholder {
    color: #bbb; font-size: 0.857rem;
}
/* 스크롤바 */
#mypage .wrap::-webkit-scrollbar {width: 0.143rem;}
#mypage .wrap::-webkit-scrollbar-thumb {
    background-color: #bbb; border-radius: 0.357rem;
}
#mypage .wrap::-webkit-scrollbar-track {}
/* 공통 */
#mypage .container {
    width: 75.714rem;
    display: flex;flex-flow: row nowrap; justify-content: center;
}
#mypage .my_edit_wrap, .bookmark_edit_wrap {display: none;}
#mypage .my_wrap, #mypage .mypage_bookmark , #mypage #edit_form { 
    width: 50%; height: 23.214rem;    padding: 0 5.714rem 0;
    overflow-y: scroll; 
}
#mypage .my_info,#mypage .bm_contents{width: 100%;}
#mypage h2 {font-size: 1.714rem; font-weight: 700; margin-bottom: 1.429rem;text-align: center;}
/* 수정하기 버튼 */
#mypage .warning_txt{
    display:block;
    width:100%;
    text-align: center;
    margin-top:10px;
    color:#e70000;
    height:12px;
    padding:12px 0;
    font-size:12px;
}
#mypage .btn_box {
    height: 5.714rem; margin-top: 3.571rem; text-align: center;
}
#mypage .btn_box.info_btn{
    margin-top:10px;
    height:auto;
}
#mypage .btn_box button {
    height: 2.429rem;width: 18.571rem;
    background-color: #434343; color: #fff;
    border-radius: 0.714rem;
    box-shadow: 0.143rem 0.143rem 0.286rem #babebc;
}
#mypage .submit_btn {
    display: block;  border-radius: 0.714rem; text-align: center; line-height: 2.429rem;
    color: #fff; background-color: #434343; width: 18.571rem; height: 2.429rem;
    box-shadow:  0.143rem 0.143rem 0.286rem #babebc;
}
#mypage .submit_btn:active {
    box-shadow: inset 0.143rem 0.143rem 0.286rem #babebc, inset -0.357rem -0.357rem 0.857rem #fff;
    background-color: #fff; color: #222;
}
/* 수정완료 버튼 */
#mypage .bm_edit_btn {}
#mypage .my_submit_btn{
    margin:10px;
    width:12rem;
}
#mypage .my_submit_btn.disabled{    
    cursor:auto;
    pointer-events:none;
    background:#666;    
}
/* 내 정보 */
#mypage .my_info {}
#mypage .my_info > li {
    margin-top: 1.429rem; height: 2.857rem; padding: 0 0.357rem;
    display: flex; flex-flow: row nowrap; justify-content: space-between; align-items: center;
}
#mypage .my_info > li:first-child {margin: 0;}
#mypage .my_info > li p {font-weight: 700; }
#mypage .my_info > li p span {}
#mypage .edit_box > li:nth-child(2) p:nth-child(2) {margin-bottom: 0.714rem;}
#mypage .edit_box > li:nth-child(2) div p:nth-child(1) .password_check {border-radius: 0.714rem 0.714rem 0 0;}
#mypage .edit_box > li:nth-child(2) div p:nth-child(2) .password_check {border-radius: 0 0 0.714rem 0.714rem;}
#mypage .my_info .info_change img{
}
#mypage .my_info .info_change{
    margin-left:5px;
}
/* 북마크 그룹 */ 
#mypage .bm_contents {}
#mypage .bm_group {
    display: flex; flex-flow: row nowrap;
}
#mypage .bm_list_btn {text-align: center; border-bottom: 1px solid #eee;}
#mypage .list_btn { 
    height: 2.143rem; width: 100%; border-right: 1px solid #eee;
    color: #babcbe;
}
#mypage .bm_group > li:last-child .list_btn {border-right:none;}
#mypage .bm_group > li {width: 25%;}
#mypage .bm_group > li > button {
    display: block; 
    width: 100%; height: 2.143rem; font-weight: 700;
    color: #babcbe;
}
#mypage .list_btn.y {font-weight: 700; color: #222; background-color: #f7f7f7;}
/* 북마크 리스트 */
/* .bm_list_all > li:first-child {display: block;} */
#mypage .bm_list_all > li {
    display: none; width: 100%;
}
#mypage .bm_list {/* 
    min-height: 5rem; display: flex;
    flex-flow: column nowrap; width: 100%; */
}
#mypage .bm_list > li {
    display: flex; flex-flow: row nowrap; justify-content: space-between;align-items: center;
    padding: 0.714rem 0.357rem; border-bottom: 1px solid #eee;
}
/* 북마크 리스트 텍스트 */
#mypage .bm_list_txt {width: 100%; border-left: 3px solid #AB35D5; padding: 0.357rem 0.714rem;}
/* 북마크 별명 */
#mypage .bm_nickname {display: flex; align-items: center;}
#mypage .bm_nickname span {color: #6B6B6B; font-weight: 700; font-size: 0.857rem;}
#mypage .bm_nickname button {height:1rem; margin-left: 0.357rem;}
#mypage .bm_nickname button img {height: 100%;}
#mypage .bm_nickname_edit {display: flex; align-items: center;}
#mypage .bm_nickname_edit p {
    box-shadow: inset 0.143rem 0.143rem 0.286rem #babebc, inset -0.357rem -0.357rem 0.857rem #fff;border-radius: 0.514rem; 
    height: 1.5rem;
}
#mypage .bm_nickname_edit p input {
    font-size: 0.857rem;box-shadow: none; height: 100%; padding: 0 1.071rem;
    border-radius: 0;border-radius: 0.514rem; 
}
#mypage .bm_nickname_edit button {height:1rem; margin-left: 0.357rem;}
#mypage .bm_nickname_edit button img {height: 100%;}
/* 북마크 주소 */
#mypage .bm_address {margin: 0.714rem 0;}
#mypage .bm_address span {
    font-size: 1.143rem;
    font-weight: 700;
}
/* 북마크 면적 */
#mypage .bm_space {}
#mypage .bm_space span {color: #6B6B6B; font-weight: 700; font-size: 0.9rem;}
#mypage .bm_space .space_fix {}
/* 북마크 맵 바로가기 */
#mypage .bm_map {}
#mypage .bm_map a {}
#mypage .bm_map a img {}
/* 내정보 수정 */
legend {display: none;}
#mypage .edit_txt {margin-bottom: 0.357rem; padding-left: 0.357rem;}
#mypage #edit_form {
    width: 50%; height: 23.214rem;
    overflow-y: scroll;
}
#mypage #edit_form::-webkit-scrollbar {width: 0.143rem;}
#mypage #edit_form::-webkit-scrollbar-thumb {
    background-color: #bbb; border-radius: 0.357rem;
}
#mypage #edit_form::-webkit-scrollbar-track {}
#mypage .mypage_bookmark::-webkit-scrollbar {width: 0.143rem;}
#mypage .mypage_bookmark::-webkit-scrollbar-thumb {
    background-color: #bbb; border-radius: 0.357rem;
}
#mypage .mypage_bookmark::-webkit-scrollbar-track {}
#mypage #edit_form fieldset {}
#mypage #edit_form fieldset .edit_box {}
#mypage #edit_form fieldset .edit_box > li {margin-bottom: 1.429rem;}
#mypage #edit_form fieldset .edit_box > li p {font-weight: 700;}
#mypage #edit_form fieldset .edit_box > li p .warning_txt {}
#mypage #edit_form fieldset .edit_box > li input {}
#mypage .my_info > li .edit_select {
    display: flex; flex-flow: row nowrap; justify-content: space-between;
    max-width: 70%;
}
#mypage .my_info > li .edit_select label {
    display: flex; flex-flow: row nowrap; justify-content: center;
}
#mypage .my_info > li .edit_select label span{}
#mypage .my_info > li .edit_select input[type=radio] {}
#mypage .my_info > li .edit_select select {width: 5.714rem; margin: 0 0.357rem;}
#mypage .my_info > li .edit_select input[type=number] {
}
#mypage input[type="number"]::-webkit-outer-spin-button,
#mypage input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
#mypage .my_info > li .edit_occu_select {}
#mypage .my_info > li .edit_occu_select select {}
#mypage .my_info > li .edit_occu_select select option {}
#mypage .select_form {
    display: flex ; flex-flow: row nowrap; justify-content: space-between;
    align-items: center; padding-left: 0.357rem;
}
#mypage .select_form > p > span {font-size: 0.714rem; margin-left: 0.357rem; color: #bbb;}
#mypage #edit_form fieldset .agree_wrap {}
#mypage #edit_form fieldset .agree_wrap > p {
    border-bottom: 1px solid #222; padding: 0.357rem 0;

}
#mypage #edit_form fieldset .agree_wrap > p input[name=agree_all] {
    width: 1.714rem;
}
#mypage #edit_form fieldset .agree_wrap > p label {
    font-size:1rem;
}
#mypage #edit_form fieldset .agree_wrap .agree_select {margin-top: 0.143rem;}
#mypage #edit_form fieldset .agree_wrap .agree_select li {
    padding:3px 0;
    display: flex; flex-flow: row nowrap; justify-content: space-between;
}
#mypage #edit_form fieldset .agree_wrap .agree_select li .clause {}
#mypage #edit_form fieldset .agree_wrap .agree_select li > p {}
#mypage #edit_form fieldset .agree_wrap .agree_select li input[name=agree] {width: 1.714rem;}
#mypage #edit_form fieldset .agree_wrap .agree_select li label {}
#mypage #edit_form fieldset .agree_wrap .agree_select li label span {
    font-size: 0.714rem;color: #757575; margin-left: 0.357rem;
}
#mypage #edit_form fieldset .agree_wrap .agree_select li a {font-size: 0.857rem;color: #757575;}
#mypage .btn_box,#mypage  .find_id,#mypage  .find_pw {
    height: 5.714rem; display: flex; justify-content: center;}
    #mypage .month {color: #bbb;}
    #mypage .edit_empty {
    box-shadow: inset 0.143rem 0.143rem 0.286rem #babebc, inset -0.357rem -0.357rem 0.857rem #fff;
    border-radius: 0.714rem; height: 2.857rem; padding: 0 1.071rem;
}
/* 성별 radio 수정 */
#mypage input[type=radio]{
    display: none;
}
#mypage input[type=radio]+label {
    box-shadow:  0.143rem 0.143rem 0.286rem #babebc;
    border-radius: 6px;
    display: inline-block;
    cursor: pointer;
    height: 1.714rem;
    width: 6.429rem;
    line-height: 1.714rem;
    text-align: center;
    font-weight:bold;
    font-size: 0.929rem;
}
#mypage input[type=radio]:checked+label{
    background-color: #434343;
    box-shadow: none;
    color: #fff;
}
/* 생년월일 */
input[type=text], input[type=password], .edit_select input[type=number], .edit_select select, select {
    box-shadow: inset 0.143rem 0.143rem 0.286rem #babebc, inset -0.357rem -0.357rem 0.857rem #fff;
    border-radius: 0.714rem; height: 2.857rem; padding: 0 1.071rem;
}
/* 개인정보 이용내역 */
#mypage .privacy_box {display: flex; justify-content: space-between; margin-top: 1.429rem;}
#mypage .privacy_box .privacy {font-weight: 700;}
#mypage .privacy_box .privacy_link a { font-size:  0.857rem;color: #757575;}
/* 북마크 수정 */
#mypage .bm_check input[type=checkbox] {display: none;}
#mypage .bm_check input[type=checkbox] + label:before{
    content:"";
    display:inline-block;
    width: 1.071rem;
    height: 1.071rem;
    line-height: 1.214rem;
    border:1px solid #babcbe;
    border-radius: 3px;
    background-position: center;
}
#mypage .bm_check input[type=checkbox]:checked + label:before{
    background-image: url(../../public/images/check.png);
    background-size: cover;
    border-color:#e70000;
}
#mypage .bookmark_empty_message{
    width:100%;
    font-size:17px; font-weight: 600;
    padding:20px 50px;
    text-align: center;
}
#mypage .submit_btn.map_btn{
    margin-top:-20px;
}
/* class */
.active {display: flex !important;  flex-flow: column nowrap;}
.add {display: block;}
.remove {display: none;}