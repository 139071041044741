@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+KR:wght@200;300;400;500;600;700;900&display=swap');/* font-family: 'Noto Serif KR', serif; */
#Company {
    min-height: 100vh;
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
}
#Company h1 {font-size: 1rem;}
#Company {margin: 0 10%;}
#Company .mobile_title {
    display: none;
}
#Company main {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
}
#Company .container {
    margin: 0 auto; position: relative; max-height: 500px;
    width: 100%; display: flex; justify-content: space-between; align-items: stretch;
}
#Company .container::after {
    content: ''; position: absolute; top: 0; left: 50%; transform: translateX(-50%);
    display: block; border: 1px solid #ddd
    ; height: 100%;
}
/* 이미지 wrap */
#Company .image_container {
    margin-right: 20px;
}
#Company .image_container img {width: 100%;}
/* 소개 wrap */
#Company .information_container {
    display: flex; flex-flow: column nowrap;
    justify-content: space-between;
    width: 500px; margin-left: 20px;
}
#Company .text_wrap {width: 100%;}
#Company .text_wrap h2 {font-size: 2rem; font-weight: 700; margin-bottom: 20px;}
#Company .text_wrap p {line-height: 1.5;}
#Company .text_wrap p span {
    display: block;
    margin-bottom: -10px;
}
#Company .download {
    text-align: center;
}
#Company .download > p {margin-bottom: 20px; width: 100%; position: relative;}
#Company .download > p span {
    color: #babcbe; font-family: 'Noto Serif KR', serif;font-size: 0.8rem;
}
#Company .download > p span::before, .download > p span::after {
    content: ''; display: block; width:38%; border-top: 1px solid #ddd;
    position: absolute; top: 55%;
}
#Company .download > p span::before {left: 0;}
#Company .download > p span::after {right: 0;}
#Company .file_wrap {
    display: flex; justify-content: space-between; 
}
#Company .file_wrap >li {position: relative; margin-right: 0.5%;}
#Company .file_img {}
#Company .file_img img {width: 100%; display: block;}
#Company .file_opacity {
    display: flex; flex-flow: column nowrap; justify-content: center; align-items: center;
    position: absolute; top: 0; left: 0; background-color: rgba(0,0,0,0.3);
    width: 100% ; height: 100%;
}
#Company .file_opacity:hover {background-color: rgba(0,0,0,0.7); transition: all 0.5s ease;}
#Company .file_opacity h3 {font-size: 1.429rem; font-weight: 700; margin-bottom: 5px;}
#Company .file_opacity p {font-size: 0.9rem; font-weight: 200;}
#Company .file_opacity h3, .file_opacity p {font-family: 'Noto Serif KR', serif; color: #fff;}