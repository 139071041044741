/*-----------------------------------------------------공통-----------------------------------------------------*/
.fixed {
    position: fixed;
    width: 100vw;
    overflow: hidden;
    transform: translate3d(0,0,0);
    -webkit-transform: translateZ(0);
    word-break: keep-all;
}
#MFullpage .sections{
    width: 100%; 
    height: calc(var(--vh, 1vh) * 100); 
    position: relative; 
    overflow: hidden;
}
#MFullpage .sections .image_box {
    height: 100%;
    text-align: center;
    display: flex;
    align-items: end;
}
#MFullpage .sections .image_box img {
    height: 80%;
}
#MFullpage .width_con .box_con {
    position: absolute;
    right: 0;
    top: 25%;
    width: 90%;
    height: 100%;
}
#MFullpage .width_con .box_con .title {
    background-color: rgba(255, 255, 255, 0.9);
    padding: 0.6rem 0;
}
#MFullpage .width_con .box_con .desc {

}
#MFullpage .width_con .box_con .desc h5 {
    line-height: 1.24;
    letter-spacing: 0px;
}
#MFullpage .sections .btn_box {
    height: 100%;
    padding-left: 20%;
    margin-top: 20%;
}
#MFullpage .sections .btn_a {
    position: relative;
}
#MFullpage .sections .btn_a img {
    position: absolute;
    left: 0;
    bottom: -30%;
}
/*-----------------------------------------------------section1-----------------------------------------------------*/
#MFullpage .section_01{
    background-color: #222;
    background-size: cover;
    background-position: bottom;
    padding:0 !important;
}
#MFullpage .section_01 .title_txt {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
#MFullpage .section_01 .title_txt h1 {}
#MFullpage .section_01 .title_txt h1 img {}
#MFullpage .section_01 .title_txt p {
    color: #fff;
}
#MFullpage .section_01 .wrap1_info {}
#MFullpage .section_01 .wrap1_info li {
    position: absolute;
    display:block;
    width: 100%;
    top: 0;
    left: 0;
    height: 100%;
    background-size: cover;
    background-position: bottom;
    align-items: flex-end;
    opacity: 0;
    pointer-events: none;
    transition: all 400ms linear;
}
#MFullpage .section_01 .wrap1_info li.active{
    opacity: 1;
    pointer-events: auto;

}
#MFullpage .section_01 .wrap1_info .first_info {
    background-image: url(../../public/images/changebg1.jpg);
}
#MFullpage .section_01 .wrap1_info .second_info {
    background-image: url(../../public/images/changebg2.jpg);
}
#MFullpage .section_01 .wrap1_info .third_info {
    background-image: url(../../public/images/changebg3.jpg);
}
#MFullpage .section_01 .wrap1_info .first_txt {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    text-align: center;
}
#MFullpage .section_01 .wrap1_info .first_txt img {
    margin-bottom: 20px;
    width: 30%;
}
#MFullpage .section_01 .wrap1_info .first_txt p {
    color: #fff;
    font-size: 2rem;
    font-weight: 700;
    width: 100%;
}
@media screen and (max-width:768px){
    #MFullpage .section_01 .wrap1_info .first_txt p {
        font-size: 1.3rem;
    }
}
#MFullpage .section_01 .wrap1_info li:first-child .info_txt {
    display: none;
}
#MFullpage .section_01 .wrap1_info li .info_txt {
    background-color: rgba(0, 0, 0, 0.5);
    text-align: center;
    width: 100%;
    padding: 10% 5%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    bottom: 0;
    left: 0;
}
#MFullpage .section_01 .wrap1_info li .info_txt h2 {
    color: #fff;
    font-size: 1.5rem;
    margin-bottom: 6%;
    font-weight: 700;
}
#MFullpage .section_01 .wrap1_info li .info_txt p {
    color: #fff; width: 100%; line-height: 2.2rem;
}
/*-----------------------------------------------------section2-----------------------------------------------------*/
#MFullpage .section_02 {}
#MFullpage .mySwiper {
    width: 100%;
    height: 100%;
    padding: 7rem 0;
    display: flex;
    align-items: center;
}
#MFullpage .swiper-slide {
    background-position: center;
    background-size: cover;
    width: 80%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
}

#MFullpage .swiper-slide .img_box {
    padding: 0 6%;
    margin-bottom: 5%;
}
#MFullpage .swiper-slide img {
    display: block;
    width: 100%;
    box-shadow: 0 0 15px 5px #3d3d3d;
}
#MFullpage .swiper_txt {
    background-color: #fff;
    text-align: center;
    padding: 0 6%;
}
#MFullpage .swiper_txt h2 {
    font-size: 1.5rem;
    font-weight: 700;
    padding: 1.4rem 0;
    border-bottom: 2px solid #505050;
}
#MFullpage .swiper_txt p {
    line-height: 1.5;
    padding: 1.4rem 0;
}
/*-----------------------------------------------------section3-----------------------------------------------------*/
#MFullpage .section_03, #MFullpage .section_04, #MFullpage .section_05 {

}
#MFullpage .section_03 h2, #MFullpage .section_04 h2, #MFullpage .section_05 h2 {
    font-size: 1.5rem;
    font-weight: 700;
    padding-left: 1.5rem;
}
#MFullpage .section_03 h5, #MFullpage .section_04 h5, #MFullpage .section_05 h5 {
    font-size: 0.9rem;
}
#MFullpage .section_03 {}
#MFullpage .section_03 .width_con {
    display: flex;
    align-items: center;
    height: 100%;
}
#MFullpage .section_03 .desc, #MFullpage .section_04 .desc, #MFullpage .section_05 .desc {
    background-color: rgba(255, 255, 255, 0.9);
    border-top: 2px solid #222;
    padding: 1.5rem;
}
#MFullpage .section_03 .width_con .map {
    height: 50%;
    margin-top: 40%;
}
#MFullpage .section_03 .width_con .map img {
    height: 100%;
}
/*-----------------------------------------------------section4-----------------------------------------------------*/
#MFullpage .section_04 .box_con {
    left: 0;
}
/*-----------------------------------------------------section5-----------------------------------------------------*/
#MFullpage .consulting_con {
    position: absolute;
    z-index: 1;
    height: auto;
}

#MFullpage .consultingForm {
    display: flex;
    padding: 30px 0 50px;
}
#MFullpage .consulting_con .left_con, #MFullpage .consulting_con .right_con {
    width: 100%;
}
#MFullpage .consulting_con .width_con {
    width: 90%;
}
/*-----------------------------------------------------section6-----------------------------------------------------*/
#MFullpage .section_06 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    align-items: center;
}
#MFullpage .section_06 .text_con {
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
}
#MFullpage .section_06 .text_con li:first-child {
    margin-bottom: 30%;
}
#MFullpage .section_06 .text_con li {
    text-align: center;
}
#MFullpage .section_06 .text_con li p:first-child {
    margin-bottom: 5%;
}
#MFullpage .section_06 .text_con li p {}
#MFullpage .section_06 .text_con li span {
    font-family:'Noto Serif' !important;
    font-size: 2rem;
}
#MFullpage .section_06 .info_con {
    position: relative;
    width: 100%;
}
#MFullpage .section_06 .info_con .image {}
#MFullpage .section_06 .info_con .image img {
    width: 100% ;
}
#MFullpage .section_06 .info_con .text_link {
    width: 100%;
    position: absolute;
    bottom: 15%;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding-left: 5%;
}

#MFullpage .section_06 .info_con .text_link .info_txt {
    width: 42%;
    display: flex;
    flex-flow: column;
    align-items: flex-end;
    margin-right: 5px;
}
#MFullpage .section_06 .info_con .text_link .info_txt li {
    font-size: 0.8rem;
    margin-bottom: 3px;
    text-align: end;
}
#MFullpage .section_06 .info_con .text_link .info_txt li:last-child {
    margin-bottom: 0;
}
#MFullpage .section_06 .info_con .text_link .link_txt {
    width: 50%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: start;
    margin-left: 5px;
}
#MFullpage .section_06 .info_con .text_link .link_txt li {
    margin-bottom: 3px;
}
#MFullpage .section_06 .info_con .text_link .link_txt li a{
    display:block;
    vertical-align : top;
    font-size: 0.8rem;

}
#MFullpage .section_06 .info_con .text_link .link_txt li:last-child {
    margin-bottom: 0;
}
/*-----------------------------------------------------fullpageHeader-----------------------------------------------------*/
#MFullpage {
    overflow-y: hidden;
}
#MFullpage header h1 {
    top:auto; 
    bottom:0; 
    opacity:0; 
    pointer-events:none; 
    transition: 250ms ease-out; 
    transition-delay:250ms;}

#MFullpage.white header h1 {
    opacity:1; 
    pointer-events:auto; 
    transition: 250ms ease-out; 
    transition-delay:50ms;}

#MFullpage .header_bg {
    position: fixed; 
    z-index: 9; 
    width: 90%;
    margin: 0 5%;
}
#MFullpage.white .burger-icon .burger-sticks:before,
#MFullpage.white .burger-icon .burger-sticks:after{
    background: #fff;
}
.qwe {
    height: 30%;
}
.btn_map {
    bottom: -10px !important;
}