@media screen and (max-width:1500px){

    #Viewer main .viewList li .text_wrap .txt_box p {
        font-size: 2.2rem;
    }

}

@media screen and (max-width:1024px){

    #Viewer main .viewList li .text_wrap .txt_box p {
        font-size: 2rem;
    }

}

@media screen and (max-width:768px){
    
    #Viewer {
        height: calc(var(--vh, 1vh) * 100);
    }
    #Viewer main .viewList li .text_wrap > p {
        writing-mode: horizontal-tb;
        position: absolute;
        right: 2%;
        bottom: 5%;
        left: auto;
    }
    #Viewer main .viewList li.hover .text_wrap .txt_box {
        backdrop-filter: blur(5px);
        background-color: rgba(0, 0, 0, 0.2);
        width: 100%;
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        padding-right: 100px;
        padding: 5% 15% 5% 5%;
    }
    #Viewer main .viewList li .text_wrap .txt_box a {
        align-self: flex-end;
    }
    #Viewer main .viewList li .text_wrap .txt_box p {
        margin: 0;
    }
    #Viewer main .viewList li .img {
        background-position: center;
    }
    #Viewer main .viewList li:nth-child(1) .img {
        background-image: url(../../public/images/3Dviewer/3D_first_wide.jpg);
    }
    #Viewer main .viewList li:nth-child(2) .img {
        background-image: url(../../public/images/3Dviewer/3D_second_wide.jpg);
    }
    #Viewer main .viewList li:nth-child(3) .img {
        background-image: url(../../public/images/3Dviewer/3D_third_wide.jpg);
    }
    #Viewer main .viewList li:nth-child(4) .img {
        background-image: url(../../public/images/3Dviewer/3D_fourth_wide.jpg);
    }
    #Viewer main .viewList li:nth-child(5) .img {
        background-image: url(../../public/images/3Dviewer/3D_fifth_wide.jpg);
    }
    #Viewer main .viewList {
        flex-flow: column nowrap;
    }
    #Viewer main .viewList li {
        width: 100%;
        height: 20%;
    }
    #Viewer main .viewList li.hover {
        height: 28%;
        width: auto;
    }
    #Viewer main .viewList li .text_wrap {
        flex-flow: row nowrap;
        padding: 0;
        align-items: flex-end;
    }
    #Viewer main .viewList li .text_wrap .txt_box a {
        font-size: 0.6rem;
    }
    #Viewer main .viewList li .text_wrap .txt_box a img {
        width: 150px;
    }
    #Viewer main .viewList li .text_wrap {}
    #Viewer main .viewList li .text_wrap > p {
        font-size: 2rem;
    }
    #Viewer main .viewList li .text_wrap .txt_box p {
        font-size: 1.5rem;
    }
    #Viewer main .viewList li.hover .text_wrap .txt_box {
        padding: 5% 20% 5% 5%;
    }


}