@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');
html {
}
#Staf {
    margin: 0 10%;
    word-break: break-all;
    height: 100vh;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    align-items: center;
}
#Staf main {
    margin: 80px 0;
    max-width: 1440px;
    width: 100%;
}
#Staf p, #Staf h3 {
    font-family: 'Montserrat';
}
#Staf .staf_wrap {
    position: relative;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
}
#Staf .staf_wrap li {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
}
#Staf .staf_wrap li h2 {
    width: 227px;
    height: 270px;
    background-size: cover;
}
#Staf .staf_wrap li:nth-child(1) h2 {
    background-image: url(../../public/images/staf/hojunW.jpg);
}
#Staf .staf_wrap li:nth-child(1) h2:hover {
    background-image: url(../../public/images/staf/hojunB.jpg);
}
#Staf .staf_wrap li:nth-child(1) h2:active {
    background-image: url(../../public/images/staf/hojunB.jpg);
}
#Staf .staf_wrap li:nth-child(2) h2 {
    background-image: url(../../public/images/staf/juwonW.jpg);
}
#Staf .staf_wrap li:nth-child(2) h2:hover {
    background-image: url(../../public/images/staf/juwon.jpg);
}
#Staf .staf_wrap li:nth-child(2) h2:active {
    background-image: url(../../public/images/staf/juwon.jpg);
}
#Staf .staf_wrap li:nth-child(3) h2 {
    background-image: url(../../public/images/staf/dongukW.jpg);
}
#Staf .staf_wrap li:nth-child(3) h2:hover {
    background-image: url(../../public/images/staf/dongukB.jpg);
}
#Staf .staf_wrap li:nth-child(3) h2:active {
    background-image: url(../../public/images/staf/dongukB.jpg);
}
#Staf .staf_wrap li:nth-child(4) h2 {
    background-image: url(../../public/images/staf/jonginW.jpg);
}
#Staf .staf_wrap li:nth-child(4) h2:hover {
    background-image: url(../../public/images/staf/jonginB.jpg);
}
#Staf .staf_wrap li:nth-child(4) h2:active {
    background-image: url(../../public/images/staf/jonginB.jpg);
}
#Staf .staf_wrap li:nth-child(5) h2 {
    background-image: url(../../public/images/staf/hyungwooW.jpg);
}
#Staf .staf_wrap li:nth-child(5) h2:hover {
    background-image: url(../../public/images/staf/hyungwooB.jpg);
}
#Staf .staf_wrap li:nth-child(5) h2:active {
    background-image: url(../../public/images/staf/hyungwooB.jpg);
}
#Staf .staf_wrap li:nth-child(6) h2 {
    background-image: url(../../public/images/staf/yubinW.jpg);
}
#Staf .staf_wrap li:nth-child(6) h2:hover {
    background-image: url(../../public/images/staf/yubinB.jpg);
}
#Staf .staf_wrap li:nth-child(6) h2:active {
    background-image: url(../../public/images/staf/yubinB.jpg);
}

#Staf .staf_wrap li .txt_box {
    width: 227px;
    height: 150px;
    padding: 10px;
    margin-top: 15px;
}
#Staf .staf_wrap li .txt_box .position {
    font-weight: 600;
    color: #818181;
}
#Staf .staf_wrap li .txt_box h3 {
    font-size: 1.3rem;
    font-weight: 700;
    margin: 8px 0 20px;
}
#Staf .staf_wrap li .txt_box .hashtag {
    line-height: 1.6;
}
/* ---------------------------------애바담 easteregg--------------------------------- */
/* egg1 과 egg5 는 추후에 알맞게 배치예정 */
#Staf .easter_egg {
    position: absolute;
    width: 20px;
    height: 100%;
    z-index: 9;
}
#Staf .easter_egg1 {
}
#Staf .easter_egg1:hover ~ .egg1 {
    display: block !important; 
}
#Staf .easter_egg2:hover ~ .egg2 {
    display: block !important; 
}
#Staf .easter_egg3:hover ~ .egg3 {
    display: block !important; 
}
#Staf .easter_egg4:hover ~ .egg4 {
    display: block !important; 
}
#Staf .easter_egg5:hover ~ .egg5 {
    display: block !important; 
}
#Staf .easter_egg6:hover ~ .egg6 {
    display: block !important; 
}
#Staf .easter_egg2 {
    left: 225px;
}
#Staf .easter_egg3 {
    left: 470px;
}
#Staf .easter_egg4 {
    left: 710px;

}
#Staf .easter_egg5 {
    right: 470px;

}
#Staf .easter_egg6 {
    right: 225px;
}
#Staf .egg {
    position: absolute;
    display: none !important;
}
#Staf .egg1 {
    height: 500px;
    bottom: -54%;
}
#Staf .egg2 {
    height: 200px;
    left: -10%;
}
#Staf .egg3 {
    height: 150px;
    right: 17%;
    top: 15%;
}
#Staf .egg4 {
    height: 60px;
    right: 0%;
    bottom: 20%;
}
#Staf .egg5 {
    height: 75px;
    left: 597px;
    top: -223px;
}
#Staf .egg6 {
    height: 200px;
    top: -30%;
    left: 25%;
}
#Staf .egg img {
    height: 100%;
}
/* -------------------------------------responsive------------------------------------- */
@media screen and (max-width:1730px){
    #Staf .staf_wrap {
        justify-content: center;
    }
    #Staf .staf_wrap li {
        width: 30%;
    }
    #Staf .easter_egg, #Staf .egg {
        display: none !important;
    }
}
@media screen and (max-width:1024px){
    
    #Staf .staf_wrap li {
        width: 50%;
    }

}
@media screen and (max-width:768px){
    
    #Staf {
        margin: 0 5%;
    }
    #Staf .staf_wrap li:nth-child(1) h2 {
        background-image: url(../../public/images/staf/MhojunW.jpg);
    }
    #Staf .staf_wrap li:nth-child(1) h2:hover {
        background-image: url(../../public/images/staf/MhojunB.jpg);
    }
    #Staf .staf_wrap li:nth-child(1) h2:active {
        background-image: url(../../public/images/staf/MhojunB.jpg);
    }
    #Staf .staf_wrap li:nth-child(2) h2 {
        background-image: url(../../public/images/staf/MjuwonW.jpg);
    }
    #Staf .staf_wrap li:nth-child(2) h2:hover {
        background-image: url(../../public/images/staf/MjuwonB.jpg);
    }
    #Staf .staf_wrap li:nth-child(2) h2:active {
        background-image: url(../../public/images/staf/MjuwonB.jpg);
    }
    #Staf .staf_wrap li:nth-child(3) h2 {
        background-image: url(../../public/images/staf/MdongukW.jpg);
    }
    #Staf .staf_wrap li:nth-child(3) h2:hover {
        background-image: url(../../public/images/staf/MdongukB.jpg);
    }
    #Staf .staf_wrap li:nth-child(3) h2:active {
        background-image: url(../../public/images/staf/MdongukB.jpg);
    }
    #Staf .staf_wrap li:nth-child(4) h2 {
        background-image: url(../../public/images/staf/MjonginW.jpg);
    }
    #Staf .staf_wrap li:nth-child(4) h2:hover {
        background-image: url(../../public/images/staf/MjonginB.jpg);
    }
    #Staf .staf_wrap li:nth-child(4) h2:active {
        background-image: url(../../public/images/staf/MjonginB.jpg);
    }
    #Staf .staf_wrap li:nth-child(5) h2 {
        background-image: url(../../public/images/staf/MhyungwooW.jpg);
    }
    #Staf .staf_wrap li:nth-child(5) h2:hover {
        background-image: url(../../public/images/staf/MhyungwooB.jpg);
    }
    #Staf .staf_wrap li:nth-child(5) h2:active {
        background-image: url(../../public/images/staf/MhyungwooB.jpg);
    }
    #Staf .staf_wrap li:nth-child(6) h2 {
        background-image: url(../../public/images/staf/MyubinW.jpg);
    }
    #Staf .staf_wrap li:nth-child(6) h2:hover {
        background-image: url(../../public/images/staf/MyubinB.jpg);
    }
    #Staf .staf_wrap li:nth-child(6) h2:active {
        background-image: url(../../public/images/staf/MyubinB.jpg);
    }
    #Staf .staf_wrap li h2 {
        background-position: center;
    }
    #Staf .staf_wrap li h2 {
        width: 150px;
        height: 200px;
        background-size: cover;
    }
    #Staf .staf_wrap li .txt_box {
        width: 150px;
        height: 220px;
    }

}