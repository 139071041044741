/* tablet */
@media screen and (max-width:1024px){

    #mypage main {padding: 10% 0; height: 100%;}
    #mypage .input_box_shadow {box-shadow: inset 0.143rem 0.143rem 0.286rem #babebc, inset -5px -5px 0.857rem #fff;
    border-radius: 0.714rem;}
    #mypage .container {display: block;}
    #mypage .my_wrap, #mypage .mypage_bookmark, #edit_form {width: 100%; height: 100%;}
    #mypage .edit_box > li:nth-child(2) div .input_box_shadow:nth-child(1) {
        border-radius: 0.714rem 0.714rem 0 0;
    }
    #mypage .edit_box > li:nth-child(2) div .input_box_shadow:nth-child(2) {
        border-radius: 0 0 0.714rem 0.714rem;
    }
    #mypage .clause {display: flex; align-items: center;}
    #mypage .clause label {display: inline-block;}
    #mypage .edit_box > li:nth-child(6) input {padding: 0 0.5rem;}
    #mypage .my_wrap,#mypage  #edit_form {margin-bottom: 20%;}
    #mypage .btn_box,#mypage  .find_id,#mypage  .find_pw {margin-top: 3.571rem;
        text-align: center; height: 100%;
    }
    #mypage .my_wrap,#mypage  #edit_form,#mypage  .mypage_bookmark  {padding: 10% 5%;}

}
/* mobile */
@media screen and (max-width:768px){
    
    /* main {padding: 20% 0;} */
    #mypage .my_submit_btn {
        width: 9rem;
        margin: 10px 0;
    }
    #mypage .my_submit_btn:first-of-type {
        margin-right: 5px !important;
    }

}