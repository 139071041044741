#loginPage{margin:0 10%;}
#loginPage .header_bg{position:relative;}
#loginPage header h1{opacity:1;}
#loginPage main {
    display: flex; justify-content: center;align-items: center;
    height: calc(100vh - 192px);
}
#loginPage legend {display: none;}
#loginPage .wrap {
    width: 1060px; height: 325px; align-items: center;
    display: flex;flex-flow: row nowrap; justify-content: center;
    position: relative;
}
/* 공통 */
#loginPage form {
    width: 50%; height: 325px; background-color: #fff;
}
#loginPage input {width: 100%;}
#loginPage .login_txt {
    display: inline-block;
    padding: 0 5px; font-weight: 700; margin-bottom: 5px;
}
#loginPage .join_box > li, .login_box {margin-bottom: 20px;}
#loginPage .login_warning_txt , p > .warning_txt, .join_warning_txt, .find_pw_warning_txt{
    font-size: 12px; color: #ff0000;
}
#loginPage input[type=text], input[type=password], .join_select input[type=number], .join_select select, .join_phone_empty {
    box-shadow: inset 2px 2px 4px #babebc, inset -5px -5px 12px #fff;
    border-radius: 10px; height: 40px; padding: 0 15px;
}
#loginPage input.join_pw_empty{
    border-radius:10px 10px 0 0;
}
#loginPage input.join_pwck_empty{
    border-radius:0 0 10px 10px;    
}
#loginPage input::placeholder {
    color: #bbb; font-size: 12px;
}
#loginPage .kakao_btn {
    border-top-left-radius: 10px; border-bottom-left-radius: 10px; overflow: hidden;
    box-shadow:  2px 2px 4px #babebc;
}
.kakao_btn:active,.naver_btn:active {box-shadow: none;}
.naver_btn {
    position:relative;
    margin: 0 5px;
    box-shadow:  2px 2px 4px #babebc;
}
#naverIdLogin{
    position:absolute;
    left:0;
    top:0;
    z-index:1;
}
.naver_real_btn{
    position:relative;
    z-index:2;
}
#loginPage .login_btn {
    border-top-right-radius: 10px; border-bottom-right-radius: 10px; 
    overflow: hidden;
    box-shadow:  2px 2px 4px #babebc;background-color: #434343; 
    cursor:pointer;
}
#loginPage .login_btn a {
    display: block;
    width: 180px; line-height: 34px;
}
#loginPage .login_btn:active {
    box-shadow: inset 2px 2px 4px #babebc, inset -5px -5px 12px #fff;
    background-color: #fff;
}
#loginPage .login_btn:active a {color: #222 !important;}
#loginPage #login_container fieldset .login_wrap {
    max-width: 530px;height: 100%; padding: 52px 80px 0; 
}
#loginPage h3 {font-size: 20px; margin-bottom:20px; font-weight: 700; text-align: center;}
/* 성별 radio 수정 */
#loginPage input[type=radio]{
    display: none;
}
#loginPage input[type=radio]+label {
    box-shadow:  2px 2px 4px #babebc;
    border-radius: 6px;
    display: inline-block;
    cursor: pointer;
    height: 24px;
    width: 90px;
    line-height: 24px;
    text-align: center;
    font-weight:bold;
    font-size:13px;
}
#loginPage input[type=radio]:checked+label{
    background-color: #434343;
    box-shadow: none;
    color: #fff;
}
/*  */
/* 로그인 wrap */
#loginPage #login_container fieldset {}
#loginPage #login_container fieldset .login_wrap .login_box .login_txt span {
    font-weight: 700;
}
a.find {
    float:right;
    font-size: 10px; color: #bbb;
}
#loginPage #login_container fieldset .login_wrap .login_box input[type=text] {}
#loginPage #login_container fieldset .login_wrap .login_box input[type=password] {}
#loginPage #login_container fieldset .login_wrap .login_warning_txt, .join_warning_txt {
    display: block;
    margin: 15px 0; height: 20px;
}

#loginPage .warning_txt{
    float:right ;
    font-size:12px; 
    color:#ff0000;
}
#loginPage .find_box .join_pwck_empty{
    margin-bottom:20px;
}
#loginPage #login_container fieldset .login_wrap .warning_txt li {}
#loginPage #login_container fieldset .login_wrap .login_btn_box {
    display: flex; height: 80px; text-align: center;
    justify-content: center; align-items: start;max-width: 260px;
    margin: 0 auto;
}
#loginPage #login_container fieldset .login_wrap .login_btn_box li {
    height: 34px;
}
#loginPage #login_container fieldset .login_wrap .login_btn_box li a {
    color: #fff;
}
#loginPage #login_container fieldset .login_wrap .login_btn_box li a img {}
/* 회원가입 wrap */
#loginPage #join_wrap {
    padding: 20px 78px 0 80px; 
    overflow-y: scroll; overflow-x: hidden;
}
#loginPage form::-webkit-scrollbar {width: 2px;}
#loginPage form::-webkit-scrollbar-thumb {
    background-color: #bbb; border-radius: 5px;
}
#loginPage #join_wrap::-webkit-scrollbar-track {}
#loginPage #join_wrap fieldset {}
#loginPage #join_wrap fieldset .join_box {}
#loginPage #join_wrap fieldset .join_box > li {}
#loginPage #join_wrap fieldset .join_box > li p {font-weight: 700;}
#loginPage #join_wrap fieldset .join_box > li p .warning_txt {}
#loginPage #join_wrap fieldset .join_box > li input {}
#loginPage #join_wrap fieldset .join_box > li .join_select {
    display: flex; flex-flow: row nowrap; justify-content: space-between;
}
#loginPage #join_wrap fieldset .join_box > li .join_select label {
    display: flex; flex-flow: row nowrap; justify-content: center;
}
#loginPage #join_wrap fieldset .join_box > li .join_select label span{}
#loginPage #join_wrap fieldset .join_box > li .join_select input[type=radio] {}
#loginPage #join_wrap fieldset .join_box > li .join_select select {width: 80px; margin: 0 5px;}
#loginPage #join_wrap fieldset .join_box > li .join_select input[type=number] {
    width: 70px;
}
#loginPage #join_wrap fieldset .join_box > li .join_select input[type=number]:last-child {
    width: 60px;
}
#loginPage input[type="number"]::-webkit-outer-spin-button,
#loginPage input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
#loginPage #join_wrap fieldset .join_box > li .join_occu_select {}
#loginPage #join_wrap fieldset .join_box > li .join_occu_select select {}
#loginPage #join_wrap fieldset .join_box > li .join_occu_select select option {}
#loginPage .select_form {
    display: flex ; flex-flow: row nowrap; justify-content: space-between;
    align-items: center; padding-left: 5px;
}
#loginPage .select_form > p > span {font-size: 10px; margin-left: 5px; color: #bbb;}
#loginPage #join_wrap fieldset .agree_wrap {}
#loginPage #join_wrap fieldset .agree_wrap > p {
    border-bottom: 1px solid #222; padding: 5px 0;

}
#loginPage #join_wrap fieldset .agree_wrap > p input[name=agree_all] {
    width: 24px;
}
#loginPage #join_wrap fieldset .agree_wrap > p label {
    font-size: 14px;
}
#loginPage #join_wrap fieldset .agree_wrap .agree_select {margin-top: 2px;}
#loginPage #join_wrap fieldset .agree_wrap .agree_select li {
    padding:3px 0;
    display: flex; flex-flow: row nowrap; justify-content: space-between;
}
#loginPage #join_wrap fieldset .agree_wrap .agree_select li .clause {}
#loginPage #join_wrap fieldset .agree_wrap .agree_select li > p {}
#loginPage #join_wrap fieldset .agree_wrap .agree_select li input{width: 24px;}
#loginPage #join_wrap fieldset .agree_wrap .agree_select li label {}
#loginPage #join_wrap fieldset .agree_wrap .agree_select li label span {
    font-size: 10px;color: #757575; margin-left: 5px;
}
#loginPage #join_wrap fieldset .agree_wrap .agree_select li a {font-size: 12px;color: #757575;}
#loginPage .join_submit,#loginPage .find_id,#loginPage .find_pw {height: 83px; display: flex; justify-content: center;}
#loginPage .join_submit_btn,#loginPage .find_id_btn,#loginPage .find_pw_btn {
    display: block;  border-radius: 10px; text-align: center; line-height: 34px;
    color: #fff; background-color: #434343; width: 260px; height: 34px;
    box-shadow:  2px 2px 4px #babebc;
}

#loginPage .find_pw_btn.disabled {
    cursor:auto;
    pointer-events:none;
    background:#666;
    
}

#loginPage .join_submit_btn:active,#loginPage .find_id_btn:active,#loginPage .find_pw_btn:active {
    box-shadow: inset 2px 2px 4px #babebc, inset -5px -5px 12px #fff;
    background-color: #fff; color: #222;
}
#loginPage #find_pw {
    overflow-x:hidden;
    overflow-y:scroll;
}
#loginPage .cover_join_btn {
    display: block;  border-radius: 10px; text-align: center; line-height: 34px;
    color: #fff; background-color: #434343; width: 260px; height: 34px;
    box-shadow:  2px 2px 4px #babebc;
}
#loginPage .cover_join_btn:active,#loginPage .find_box button[type=submit]:active {
    box-shadow: inset 2px 2px 4px #babebc, inset -5px -5px 12px #fff;
    background-color: #fff; color: #222;
}
#loginPage .month {color: #bbb;}
/* 아이디 찾기 */
#loginPage .find_box input[type=number] {
    box-shadow: inset 2px 2px 4px #babebc, inset -5px -5px 12px #fff;
    border-radius: 10px;
    height: 40px;
    padding: 0 15px;
}
#loginPage .find_box > li {
    margin-bottom: 20px;
}
#loginPage .find_box > li:last-child {margin-bottom: 0;}
#loginPage #find_id,#loginPage #find_pw {
    padding: 20px 80px 0;
    position: absolute; right: 0; top: 0;
}
#loginPage #find_id fieldset,#loginPage #find_pw fieldset {
    height: 100%;
    display: flex; flex-flow: column nowrap; justify-content: space-between;
}
#loginPage #find_id .find_box {}
#loginPage #find_id .find_box li {}
#loginPage #find_id .find_box li p {}
#loginPage #find_id .find_box li p {}
#loginPage .find_box li p {
    position: relative;
}
#loginPage .find_box .confirm_email{
    background-color: #434343;
    box-shadow: 2px 2px 4px #babebc;
    color: #fff;
    border-radius: 0 10px 10px 0;
    position: absolute; right: 0; top: 0;
    width: 70px; height: 100%;
}
/* 비밀번호 재설정 */
/* 커버 */
#loginPage .overlay {
    background-color: #fff; 
    width: 50%; height: 325px;
    position: absolute; top: 0; right:0; padding: 70px 80px 0;
    text-align: center;
    transition: all 500ms ease-out;
}
#loginPage .wrap.active .overlay{
    transform:translateX(-100%);
}
#loginPage .overlay_btn_box {height: 80px; margin-top: 50px; display: flex; justify-content: center;}
#loginPage .overlay h2 {font-size: 32px; margin-bottom: 30px;    font-weight: 500;}
#loginPage .overlay p {font-size: 20px;line-height: 1.5;letter-spacing: -0.2px;}
#loginPage .wrap.active .overlay_right{opacity:0;}
#loginPage .overlay_right{ z-index:4;}
#loginPage .overlay_right p {}
#loginPage .overlay_right .overlay_btn {}
#loginPage .wrap.active .overlay_left{opacity:1; z-index:4;}
#loginPage .overlay_left{opacity:0; z-index:3;}
#loginPage .overlay_left h2  {}
#loginPage .overlay_left p {}
#loginPage .overlay_left .overlay_btn {}
#loginPage #join_wrap.front {z-index: 4;}
#loginPage #find_id.front {z-index: 4; display: block !important;}
#loginPage .overlay_back {z-index:2;}
/* 클래스 추가 */
#loginPage .disabled {
    opacity: 0.6;
    pointer-events: none;
}