body {
}
header, footer{}
#Fullpage h2,h3,h4{line-height:1em;}

#Fullpage h1{font-size:2.857rem; font-weight:600;}
#Fullpage h2{font-size:1.786rem; font-weight:600;}
#Fullpage h3{font-size:1.429rem;}
#Fullpage h4{font-size:1.286rem; line-height: 1.4em;}
#Fullpage h5{font-size:0.929rem; line-height:1.5em;}

#Fullpage .btn_a{position:relative; height:35px; font-size:1.286rem;}
#Fullpage .btn_a:hover img{transform:translateX(10px);}
#Fullpage .btn_a img{position:absolute; left:0; bottom:0; transition:all 250ms ease-out;}

.hide{opacity:0; pointer-events:none;}
.disabled{opacity:.6; pointer-events:none;}

/* header */
header {
    margin: 0 auto;
    max-width: 1440px;
    position: relative;
    height: 100px;
    display: flex; flex-flow: row nowrap; justify-content: center;
    align-items: flex-end;
}

.menu {
    overflow: hidden;
    position: absolute;
    top: 70px;
    left: 0;
    max-width: 0;
    transition: 0.5s ease;
    z-index: 1;
}
.menu .menu_contents {
    max-width: 130px; padding: 50px 30px;
    background-color: rgba(0,0,0,0.5);
    white-space:nowrap;
}
.menu .menu_contents li {margin-bottom:0;}
.menu .menu_contents li.about {margin: 20px 0;}
.menu .menu_contents li.people {margin-top: 20px;}
.menu .menu_contents li a {color: #fff;}

.burger-icon {
cursor: pointer;
display: inline-block;
position: absolute;
z-index: 2;
padding: 8px 0;
bottom: 1px;
left: 0;
user-select: none;
width: auto;
margin: 0;
}

.burger-icon .burger-sticks {
display: block;
height: 2px;
position: relative;
transition: background .2s ease-out;
width: 25px;
}

.burger-icon .burger-sticks:before,
.burger-icon .burger-sticks:after {
background: #000 ;
content: '';
display: block;
height: 100%;
position: absolute;
transition: all .2s ease-out;
width: 100%;
}


#Fullpage .header_bg {
    position: absolute; z-index: 9; width: 100%;
}
#Fullpage.white .burger-icon .burger-sticks:before,
#Fullpage.white .burger-icon .burger-sticks:after{
    background: #fff;
}

.burger-icon .burger-sticks:before {
top: 7px;
}

.burger-icon .burger-sticks:after {
top: -7px;
}

.burger-check {
display: none;
}

.burger-check:checked~.menu {
max-width: 130px;
}

.burger-check:checked~.burger-icon .burger-sticks {
background: transparent;
}

.burger-check:checked~.burger-icon .burger-sticks:before {
transform: rotate(-45deg);
}

.burger-check:checked~.burger-icon .burger-sticks:after {
transform: rotate(45deg);
}

.burger-check:checked~.burger-icon .burger-sticks:before,
.burger-check:checked~.burger-icon .burger-sticks:after{
    background: #fff !important;
}


.burger-check:checked~.burger-icon:not(.steps) .burger-sticks:before,
.burger-check:checked~.burger-icon:not(.steps) .burger-sticks:after {
top: 0;
}

.outer {
    height: 100vh;
    transition:transform 500ms ease-out;
}
  /* 화면에서 스크롤바 안보이게 */
.outer::-webkit-scrollbar {
    display: none;
}
.three_con{
    position:relative;
    width:calc(100% + 25px);
    margin:0 -12.5px;
    overflow:hidden;
}
.three_con li{
    width:calc(33.3333% - 25px);
    margin:0 12.5px;
    background:#fff;
    float:left;
}


table {
    width: 100%;
    border: 1px solid #444444;
    border-collapse: collapse;
}
th, td {
    border: 1px solid #444444;
    padding:5px;
}
.btns{cursor:pointer;}

/*-----------------------------------------------------header-----------------------------------------------------*/
.header_bg {width: 100%;
}
header {margin: 0 auto; 
    position: relative; max-width: 1440px;
    height: 100px;
    display: flex; flex-flow: row nowrap; justify-content: center;
    align-items: flex-end;box-sizing: border-box;
}
header h1 {
}
header h1 a {
    display: block;
    width: 120px;

}
header h1 a img {
    display: block;
    width: 100%;
}


/*-----------------------------------------------------footer-----------------------------------------------------*/
.footer_bg {}
footer {text-align: center; padding: 15px 0 ;}
.footer_info {}
.footer_info li {}
.footer_info li:last-child {margin-right: 0;}
.footer_link {}
.footer_link li {}
.footer_link li, .footer_info li {display: inline-block; margin-right: 20px;}
.footer_info li:last-child, .footer_link li:last-child {margin-right: 0;}
.footer_link li a {font-weight: 500;}
footer p {}
.footer_link, .footer_info{margin-bottom: 10px;}
.footer_info li, footer p, address { color: #898989;} 