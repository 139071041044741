html {word-break: keep-all;}
#Mappage{
    position:relative;
    width:100vw; height: calc(var(--vh, 1vh) * 100);
    overflow:hidden; 
}

#Mappage .pano_close{
    position:fixed;
    left:50%; top:80px;
    width:40px; height:40px;
    box-shadow:2px 2px 4px 0 rgba(0,0,0,.25);
    border-radius:20px;
    cursor:pointer;
    background:#fff;
    z-index:1;
}
#Mappage .pano_close:after,
#Mappage .pano_close:before{
    position:absolute;
    left:50%; top:50%;
    width:18px; height:2px;
    content: '';
    transform:translate(-50%,-50%) rotate(45deg);
    background:#737373;
}
#Mappage .pano_close:before{
    transform:translate(-50%,-50%) rotate(135deg);
}
#Mappage .pano_close:before{}
#Mappage .pano_close.disabled{
    display:none;
}

#Mappage .naver_map:focus{outline:none;}
#Mappage .button_wrap{position:fixed; right:0; top:0; display: none;}
#Mappage .header{position:fixed; left:0; top:0; width:100%; height:60px; padding:12px 30px; background:#434343;}
#Mappage .header .contentWrap {width:540px; height:100%; float:left;}
#Mappage .header .contentWrap .imageBox{float:left; margin-left:49.7px; height:100%;}
#Mappage .header .contentWrap .imageBox img.logo{height:18px; margin-top:8px;}
#Mappage .header .contentWrap .inputBox{position:relative; float:left; margin-left:14.7px; width:350px; height:100%; background:#fff;}
#Mappage .header .contentWrap .inputBox input{width:100%; height:100%; padding:10px; font-weight:600; font-size:16px; box-shadow:none; border-radius:0; vertical-align:top;}
#Mappage .header .contentWrap .inputBox img.search{position:absolute; right:10px; top:5px;}
#Mappage .header .my_page,
#Mappage .header .book_mark{float:right; height:28px; margin:0 10px; padding:3px;}
#Mappage .header .my_page{margin:0;}

#Mappage .mobile_menu_dot, #Mappage .M_side_menu {
    display: none;
}
#Mappage .side_menu {position:fixed; right:30px; top:80px; width:40px; text-align:center;}
#Mappage .side_menu div{font-size:10px; font-weight:600; color:#737373;}
#Mappage .side_menu .custom_menu_wrap,
#Mappage .side_menu .zoom_menu_wrap{width:100%; padding:5px; background:#fff; box-shadow:2px 2px 4px 0 rgba(0,0,0,.25);}
#Mappage .side_menu .custom_menu_wrap img{height:20px;}
#Mappage .side_menu .custom_menu_wrap > div{margin: 10px 0;}
#Mappage .side_menu .custom_menu_wrap > div.purple div{color:#7C259B;}
#Mappage .side_menu .custom_menu_wrap .mapTypeButton > div{margin: 10px 0;}
#Mappage .side_menu .custom_menu_wrap .row{width:100%; height:1px; margin: 5px 0; background:#d6d6d6;}
#Mappage .side_menu .custom_menu_wrap .cadastral img{}
#Mappage .side_menu .zoom_menu_wrap{display:inline-block; margin-top:10px; padding:0;}
#Mappage .side_menu .zoom_menu_wrap > div{width:100%; height:40px; box-sizing:content-box;}
#Mappage .side_menu .zoom_menu_wrap div{position:relative;}
#Mappage .side_menu .zoom_menu_wrap div:active:after,
#Mappage .side_menu .zoom_menu_wrap div:active:before{background:#7C259B;}
#Mappage .side_menu .zoom_menu_wrap .plus_zoom:after,
#Mappage .side_menu .zoom_menu_wrap .plus_zoom::before,
#Mappage .side_menu .zoom_menu_wrap .minus_zoom:after,
#Mappage .side_menu .zoom_menu_wrap .minus_zoom::before{position:absolute; left:50%; top:50%; width:18px; height:2px; transform:translate(-50%,-50%);  content:''; background:#737373;}
#Mappage .side_menu .zoom_menu_wrap .plus_zoom:after{transform:translate(-50%,-50%) rotate(90deg);}
#Mappage .side_menu .zoom_menu_wrap .minus_zoom{}
#Mappage .side_menu .zoom_menu_wrap .row{width:30px; height:1px; margin:5px; background:#d6d6d6;}

#Mappage .informationWrap .loadingModule{position:absolute; left:0; top:0; width:100%; height:100%; background:rgba(255,255,255,.9); z-index:99999;}
#Mappage .informationWrap .loadingModule:after{position:absolute; left:0; top:0; width:100%; height:100%; content:''; margin-top:24px; background:url(../../public/images/map/image_loading_02.png) 50% 50% no-repeat;}
#Mappage .informationWrap .loadingModule .loadingImage{position:absolute; left:50%; top:50%; transform:translate(-50%,-50%);}
#Mappage .loadingImage{
    fill: none;
    stroke: #737373;
    stroke-width: 2px;
    stroke-dasharray: 500;
    stroke-dashoffset: 0;
    animation: ani1 .5s infinite;}
@keyframes ani1{
    0%   {stroke-dashoffset: -500;}
    100% {stroke-dashoffset: 0;}
}
#Mappage .informationWrap{
    position:fixed;
    left:30px; top:80px; 
    width:400px; min-height:422px;
    padding:30px 35px 40px 35px; 
    box-shadow:3px 3px 6px rgba(0,0,0,.25); background:#fff; z-index:999;
}
#Mappage .informationWrap .titleBox{font-size:20px; font-weight:800; white-space:normal; margin-right:40px;}
#Mappage .informationWrap .subTitleBox{margin-top:15px; font-size:16px; font-weight:600; color:#737373; white-space:normal;}
#Mappage .informationWrap .book_mark{position:absolute; right:0; top:0;}
#Mappage .informationWrap .green_belt{
    margin-top:110px;
    text-align:center;
    color:#d02020;
    font-size:22px; font-weight:600;
    line-height: 1.5em;

}
#Mappage .informationWrap .infoBox{margin-top:15px;}
#Mappage .informationWrap .infoBox .tabMenuUl{display:inline-block; width:100%; border-bottom:1px solid #000;}
#Mappage .informationWrap .infoBox .tabMenuUl .tabMenu{position:relative; float:left; width:25%; margin-top:9px; padding:4px 0; font-size:12px; font-weight:700; text-align:center; background:#eee; border-right:1px solid #fff; cursor:pointer;}
#Mappage .informationWrap .infoBox .tabMenuUl .tabMenu.active{margin:0; padding-top:10px; background:#fff; font-size:16px; color:#000; border-left:1px solid #000; border-right:1px solid #000;}
#Mappage .informationWrap .infoBox .tabMenuUl .tabMenu:before{content:""; position:absolute; left:0; top:0px; width:100%; height:3px;}
#Mappage .informationWrap .infoBox .tabMenuUl .tabMenu.active:before{background:#575757; height:5px;}
#Mappage .informationWrap .infoBox .tabMenuUl .tabMenu:after{content:""; position:absolute; left:0; bottom:-1px; width:100%; height:1px;}
#Mappage .informationWrap .infoBox .tabMenuUl .tabMenu.active:after{background:#ffffff;}
#Mappage .informationWrap .infoBox .tabContent{height:255px; border:1px solid #000; border-top:0; border-right:0; margin-top:-2px; padding:20px 5px;}
#Mappage .informationWrap .infoBox .tabContent.bookmarks{height:321px; padding:15px 0 60px 0;}
#Mappage .informationWrap .infoBox .tabContent .content_wrap{position:relative;}
#Mappage .informationWrap .infoBox .tabContent .content_wrap:after{position:absolute; left:76px; top:0; width:1px; height:100%; content:''; background:rgba(17,17,17,.2);}
#Mappage .informationWrap .infoBox .tabContent .content_wrap .content_box{position:relative; width:100%; margin-bottom:17px;}
#Mappage .informationWrap .infoBox .tabContent .content_wrap .content_box .title{display:inline-block; width:67px; margin:0 21px 0 0; text-align:right; font-size:12px; font-weight:600; line-height:1em; margin-top:1px; vertical-align:top; color:#737373;}
#Mappage .informationWrap .infoBox .tabContent .content_wrap .content_box .content{display:inline-block; font-size:14px; color:#111; font-weight:800;}
#Mappage .informationWrap .infoBox .tabContent .content_wrap .content_box .content span{font-size:12px; color:#737373} 
#Mappage .informationWrap .infoBox .tabContent .content_wrap .content_box .content span.total{display:inline-block;  margin-right:5px; font-size:14px; font-weight:700; vertical-align:top;}
#Mappage .informationWrap .infoBox .tabContent .content_wrap .content_box .content .allowBuildingList{position:relative; width:155px; padding:0 5px; height:24px; font-size:14px; font-weight:800; border-radius:5px; box-shadow: 0px 1px 1px #000;}
#Mappage .btn_b{position:absolute; right:0; bottom:0 ; padding:7px 30px; border-bottom:1px solid #000; font-weight:900; font-size:14px; color:#111; cursor:pointer;}
#Mappage .btn_b:before,
#Mappage .btn_b:after{transition:all 250ms ease-out;}
#Mappage .btn_b:hover:before,
#Mappage .btn_b:hover:after{margin-right:-10px;}
#Mappage .btn_b:after{position:absolute; right:9px; bottom:-5px; content:''; width:1px; height:100%; transform:rotate(-45deg); background:#000;}
#Mappage .btn_b:before{position:absolute; right:0; bottom:-1px; content:''; width:100%; height:1px; transform-origin:bottom; background:#000;}
#Mappage .informationWrap .noneBox {
    position:relative;
}
#Mappage .informationWrap .infoBox .mobile_allowBuilding{
    display:none;
}


/*------------------------------------------------------------북마크 리스트------------------------------------------------------------*/

#Mappage #bookmark.infoBox{
    position: relative;
    margin-top:0;
}
#Mappage .bookmark_list{
    position: relative;
    width:100%;
    height:100%;
    margin-bottom:30px;
    overflow-y:scroll;
}
#Mappage .bookmark_empty_message{
    width:100%;
    font-size:20px; font-weight: 600;
    padding:20px 50px;
    text-align: center;    
}
#Mappage .tabContent.bookmarks:after{
    position:absolute; content:'';
    right:2px; top:46px;
    width:1px; height:245px;
    background:#dddddd;
}
#Mappage .bookmark_list::-webkit-scrollbar {
    width: 5px;
}
#Mappage .bookmark_list::-webkit-scrollbar:after{
    
}
#Mappage .bookmark_list::-webkit-scrollbar-thumb {

    background-color: #dddddd; border-radius: 2px;
}
#Mappage .bookmark_list::-webkit-scrollbar-track {   
} 
#Mappage .bookmark {
    position:relative;
    display:block;
    padding:15px 10px;
    margin:0 10px;
    border-bottom:1px solid #dfdfdf;
    cursor:pointer;
}
#Mappage .bookmark .color{
    position:absolute;
    left:0; top:50%;
    margin:0;
    width:3px; height:calc(100% - 30px);
    transform: translate(0,-50%);
    content:'';
    background:#AB35D5;
}
#Mappage .cmprsEstmt .bookmark:after{
    display: none;
}
#Mappage .cmprsEstmt .bookmark .marker {
    overflow: hidden;
}
#Mappage .cmprsEstmt .address_info_wrap > div::after {
    content: '';
    position: absolute;
    left: 12px;
    top: 0;
    border: 10px solid transparent;
    height: 30px;
    width: 10px;
    border-left-width: 0;
    border-top-width: 0;
    border-right-color: inherit;
}
#Mappage .cmprsEstmt .address_info_wrap > div::before {
    content:'';
    position:absolute;
    left:15px;
    top: 0;
    border: 10px solid transparent;
    height: 30px;
    width: 10px;
    border-right-width: 0;
    border-top-width: 0;
    border-left-color: inherit;
}
#Mappage .bookmark:hover{background:#ddd;}
#Mappage .bookmark div{}
#Mappage .bookmark .name{
    font-size:12px; font-weight:700;
}
#Mappage .bookmark .title {
    font-size:16px; font-weight:800;
    line-height:1.2em;
}
#Mappage .bookmark .plot {
    margin-top:8px;
    font-size:13px; font-weight:700;
    color:#777777;
}
#Mappage .bookmark .checkbox{
    width:20px; height:20px;
    border-color:#ff2828;
}
#Mappage .bookmark .checkbox{display:none;}
#Mappage .bookmark .checkbox + label{
    position:absolute;
    right:10px; top:50%;
    width:20px; height:20px;
    transform:translate(0,-50%);
    box-sizing: border-box;
    display:inline-block;
    border:1px solid #ff2828;
    border-radius:3px;
    z-index:1;
}
#Mappage .bookmark .checkbox:checked + label::after{
    position:absolute;
    left:50%; top:50%;
    transform:translate(-50%,-50%) rotate(45deg);
    margin:2px 0 0 -3px;
    width:6px; height:2px;
    content:'';
    background:#ff2828;
    
}
#Mappage .bookmark .checkbox:checked + label::before{
    position:absolute;
    left:50%; top:50%;
    width:10px; height:2px;
    transform:translate(-50%,-50%) rotate(-45deg);
    margin:0 0 0 2px;
    content:'';
    background:#ff2828;
    
}

/*------------------------------------------------------------견적 비교------------------------------------------------------------*/
#Mappage .cmprsEstmt{
    position:fixed;
    left:0; bottom:0;
    width:100vw; height:370px;
    border-top:1px solid #000;
    background:#fff;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: end;
}

#Mappage .cmprsEstmt .table_head {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    text-align:right;
    margin-right: 2px;
    margin-bottom: 50px;
}
#Mappage .cmprsEstmt .head{
    padding: 0 20px;
    height: 28px;
    line-height: 28px;
    font-size:15px;
    font-weight:700;
    border-bottom: 1px dashed #000;
}
#Mappage .cmprsEstmt .head:last-child {
    border: none;
}
#Mappage .cmprsEstmt .info {
    border-left: 1px dashed #000;
    height: 100%;
    width:75%;
    max-width: 1400px;
    overflow: hidden;
}
#Mappage .cmprsEstmt .bookmark_wrap{
    width:100%;
    display: flex;
}
#Mappage .cmprsEstmt .bookmark_wrap > ul {
    width: 100%;
}

#Mappage .cmprsEstmt .bookmark{
    display:inline-block;
    margin: 0 0 20px 0;
    border:0;
    cursor:auto;
    height: 90px;
}
#Mappage .cmprsEstmt .bookmark:first-child {}
#Mappage .cmprsEstmt .bookmark:hover{background:transparent;}
#Mappage .cmprsEstmt .address_info_wrap{
    width:100%;
    display: flex;
    height: 100%;
}
#Mappage .cmprsEstmt .address_info_wrap > div{
    position: relative;
    width: 100%;
    text-align: center;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-end;
    padding-bottom: 50px;
}
#Mappage .cmprsEstmt .address_info_wrap > div:nth-child(2n) {
    background-color: #eee;
}
#Mappage .cmprsEstmt .info_title_M {
    display: none;
}
#Mappage .cmprsEstmt .address_info{
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
}
#Mappage .cmprsEstmt .address_info .content{
    border-bottom: 1px dashed #000;
    font-size:16px;
    font-weight:800;
    padding: 0 20px;
    height: 28px;
    line-height: 28px;
}
#Mappage .cmprsEstmt .address_info .content:last-child {
    border: none;
}
#Mappage .cmprsEstmt .address_info .content span{
    font-weight:700;
    font-size: 14px;
}
#Mappage .cmprsEstmt .allow_building_btn_con{
    position:absolute;
    right:110px; top:3px;
    transform:translate(0,-100%);
}

#Mappage .cmprsEstmt .allow_building_btn{
    position:absolute;
    left:50%; bottom:8px;
    width:100%;
    transform:translate(-50%,0);
}

#Mappage .cmprsEstmt .ul_con{
    width:100px;
    text-align:center;
    overflow:hidden;
    margin-top:-1px;
    float:left;
    -webkit-mask-image: url("../../public/images/map/icon_house.svg");
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-position:50% 50%;
    -webkit-mask-size: 150%;
}
#Mappage .cmprsEstmt .prev_btn{
    margin:20px 6px 0 6px;
    float:left;
}
#Mappage .cmprsEstmt .next_btn{
    margin:20px 5px 0 5px;
    float:left;
}
#Mappage .cmprsEstmt .prev_btn.disable,
#Mappage .cmprsEstmt .next_btn.disable{
    opacity:0;
    pointer-events:none;
}


#Mappage .cmprsEstmt .building_list_ul{
    width:700px;
    transition:all 250ms ease-out;
    list-style:none;
}
#Mappage .cmprsEstmt .building_list{
    display:inline-block;
    width:100px;
    font-size:17px; font-weight:700;
    line-height:1.2em;
}

#Mappage .cmprsEstmt .not_allow{
    margin-bottom: 50px;
    font-size:20px; font-weight:800;
}

#Mappage .cmprsEstmt .allowBuildingList{
    position:relative; 
    width:80%; height:24px; 
    margin-top:10px; padding:0 5px; 
    font-size:14px; font-weight:800; 
    border-radius:5px; box-shadow: 0px 1px 1px #000;
}
#Mappage .cmprsEstmt .building_list_text{
    font-size:15px;
    font-weight:700;
}

#Mappage .cmprsEstmt .empty_message{
    width:100%;
    font-size:24px; font-weight: 600;
    padding:20px 50px;
    text-align: center;
    line-height:100%;
}
/*------------------------------------------------------------컨설팅------------------------------------------------------------*/
.consulting_con{
    position:fixed;
    left:0; bottom:0;
    width:100%; height:400px;
    background:#fff;
    box-shadow:-1px -1px 9px rgba(0,0,0,.3); 
    z-index:1;
}
.consulting_con .width_con{
    max-width: 1200px; height:100%;
    margin: 0 auto; padding:40px 0;
}
.consulting_con .select_title{
    position:relative;
    margin-left:10px;
    font-size:18px; font-weight:700;
}
.consulting_con .left_con ,
.consulting_con .right_con
{
    float:left;
    width:50%;
    padding:0 50px;
}
.consulting_con .left_con{
    position:relative;
    overflow:hidden;
    height:250px;
}
.consulting_con .left_con:after{
    position:absolute;
    right:0; top:0;
    content:'';
    width:1px; height:100%;
    background:rgba(211,211,211,.8);
}
.consulting_con .right_con{
}
.consulting_con .select_title{
}
.consulting_con .consulting_ul{
    position:relative;
    width:100%; max-height:162px;
    overflow-y: scroll;
    background:#fff;
    z-index:9;
}
.consulting_con .checkbox_list{
    position: relative;
    width:100%; height:40px;
}

.consulting_con .checkbox_list .title{
    width:100%;
    font-size:15px; font-weight:700;
    padding:12.5px 0;    
    border-bottom:1px solid #dfdfdf;
}


.tabContent.bookmarks:after{
    position:absolute; content:'';
    right:37px; top:76px;
    width:1px; height:245px;
    background:#dddddd;
}
.consulting_ul::-webkit-scrollbar {
    width: 3px;
}
.consulting_ul::-webkit-scrollbar:after{
    
}
.consulting_ul::-webkit-scrollbar-thumb {

    background-color: #dddddd; border-radius: 2px;
}
.consulting_ul::-webkit-scrollbar-track {   
}

.consulting_ul.hide{
    height:0;
    opacity:0;
    pointer-events:none;
}

.consulting_con .checkbox{display:none;}
.consulting_con .checkbox + label{
    position:absolute;
    right:10px; top:50%;
    width:20px; height:20px;
    transform:translate(0,-50%);
    box-sizing: border-box;
    display:inline-block;
    border:1px solid #ff2828;
    border-radius:3px;
    z-index:1;
}
.consulting_con .checkbox:checked + label::after{
    position:absolute;
    left:50%; top:50%;
    transform:translate(-50%,-50%) rotate(45deg);
    margin:2px 0 0 -3px;
    width:6px; height:2px;
    content:'';
    background:#ff2828;
    
}
.consulting_con .checkbox:checked + label::before{
    position:absolute;
    left:50%; top:50%;
    width:10px; height:2px;
    transform:translate(-50%,-50%) rotate(-45deg);
    margin:0 0 0 2px;
    content:'';
    background:#ff2828;
    
}

.consulting_con .consulting_select{
    width:100%; height:40px; 
    margin:10px 1px 20px; padding:12px 12px;
    font-size:15px; font-weight:700;
    color:#737373;
    border-radius:5px;
    background:#fff;
    box-shadow: inset 2px 2px 4px #babebc, inset -5px -5px 12px #fff;
}
.input_cover {
    box-shadow: inset 2px 2px 4px #babebc, inset -5px -5px 12px #fff;
    width:100%; height:40px;
    border-radius:5px;
    margin: 10px 1px 20px;
}
.consulting_input::placeholder,
.consulting_detail_input::placeholder {
    color: #737373;
}
.input_cover:nth-of-type(2) {
    height: 200px;
}
.consulting_con .consulting_input,
.consulting_con .consulting_detail_input {
    font-size:15px; font-weight:700; padding:12px;
    width:100%; height: 100%;
    color:#737373;
}
.consulting_con .consulting_detail_input {
    line-height:1.3em;
    resize:none;
}

.consulting_con .consulting_select{position:relative; height:auto;}

.consulting_con .consulting_select_title{
    
    font-size:15px; font-weight:700;
    color:#737373;
    white-space:nowrap;
}


.consulting_con .submit_btn{
    position:absolute;
    left:50%; bottom:40px;
    transform:translate(-50%,0);
    width:300px; height:40px;
    padding:10px 0;
    border-radius:10px;
    font-size: 20px; font-weight: 700;
    text-align: center;
    color:#fff;
    background:#757575;
    box-shadow: 2px 2px 5px rgba(0,0,0,.25);
    cursor:pointer;
}
.consulting_budget .row_input{
    position:relative;
    width:calc(100% - 20px); height:2px;
    margin:26px 10px 0 10px;
    background:#d9d9d9;
}



.consulting_background{
    position:absolute;
    left:0; top:0;
    width:100%; height:100%;
}

.row_input .cube{
    position: absolute;
    left:0; top:-6px;
    transform:translate(-50%,0);
}
.row_input .cube:nth-child(1){left:0;}
.row_input .cube:nth-child(2){left:25%;}
.row_input .cube:nth-child(3){left:50%;}
.row_input .cube:nth-child(4){left:75%;}
.row_input .cube:nth-child(5){left:100%;}

.row_input .row_dark{
    position:absolute;
    left:0; top:-5px;
    width:33.3333%; height:2px;
    background:#434343;
    border-top:5px solid #fff;
    border-bottom:5px solid #fff;
    box-sizing:content-box;
}

.row_input .cube_purple{
    position:absolute;
    left:0; top:-6px;
    transform:translate(-50%,0);
}

.row_input .range {
    position:absolute;
    left:-5px; top:-5px;
    width:calc(100% + 10px);
}


 /* (Optional) Apply a "closed-hand" cursor during drag operation. */


input.range{pointer-events:none; opacity:0; z-index:1;}
input.range::-webkit-slider-thumb{
    pointer-events:all;
    cursor: move; /* fallback if grab cursor is unsupported */
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
}
input.range::-webkit-slider-thumb:active {
    cursor: grabbing ;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
}
.budget{
    position:absolute;
    width:120px; height:30px;
    right:0; top:-6px;
    padding:7.5px 0;
    font-size:15px; font-weight:700;
    text-align:center;
    color:#000000;
    border-radius:5px;
    background:#fff;
    box-shadow: inset 2px 2px 4px #babebc, inset -5px -5px 12px #fff;
    
}

.budget_text{
    position:relative;
    margin-top:10px;
}
.budget_text .left{
    text-align:left;
    font-size:12px;
    font-weight:800;
}
.budget_text .right{
    position:absolute;
    right:0; top:0;
    font-size:12px;
    font-weight:800;
}


/*------------------------------------------------------------북마크 저장창------------------------------------------------------------*/
#Mappage .bookmark_window,
#Mappage .subcategory_window
{
    position:absolute;
    right:0; top:0;
    width:300px; height:100%;
    padding:20px 10px 40px 20px;
    opacity:1;
    transform:translate(100%,0);
    pointer-events: auto;
    background:#fff;
    transition:all 250ms ease-out;
    border-left:solid 1px rgba(0,0,0,.2);
    box-shadow:3px 3px 6px rgba(0,0,0,.25);
}
#Mappage .bookmark_window.hidden,
#Mappage .subcategory_window.hidden{
    opacity:0;
    transform:translate(95%,0);
    pointer-events: none;
}

#Mappage .list_add_wrap {
    position:relative;
    padding:0 10px 10px 10px;
    margin-right:14px;
    text-align:center;
    font-size:18px; font-weight:600;
    border-bottom:1px solid #ddd;
    cursor:pointer;
}
#Mappage .list_icon{
    position:absolute;
    right:20px;top:35%;
    width:20px; height:10px;
    transform:translateY(-50%);
    transition:all 250ms ease-out;
}
#Mappage .list_icon:before,
#Mappage .list_icon:after{
    position:absolute;
    content:'';
    right:40%; top:20%;
    width:60%; height:3px;
    transform:rotate(40deg);
    background: #434343;
    border-radius:1.5px;
}
#Mappage .list_icon:before{
    right:5%;
    transform:rotate(-40deg);
}
#Mappage .list_icon.rotate{
    transform:translateY(-50%) rotate(-180deg);
    top:30%;
}
#Mappage .name_wrap{
    position:relative;
    border-bottom:1px solid #000; 
    margin-top:15px;
}
#Mappage .name_wrap:first-child{
    margin:0;
}
#Mappage .bookmark_name{
    width:85%; height:auto;
    padding:10px 5px;
    box-shadow:none;
    border-radius:0;
    font-size:16px; font-weight:600;
}
#Mappage .bookmark_name:focus{
    outline:none;
}
#Mappage .text_counter{
    position:absolute;
    right:10px; top:50%;
    transform:translate(0,-50%);
    font-size:12px; font-weight:600;
    color:#737373;

}
#Mappage .text_counter span{
    font-size:12px; color:#434343;
}
#Mappage .list_wrap{
    padding:15px 0;
    border-bottom:1px solid #000;
}
#Mappage .bookmark_add_list_con{
}
#Mappage .bookmark_add_list:first-child{
    margin-top:0;
}
#Mappage .bookmark_add_list{
    position:relative;
    margin-top:10px;
}

#Mappage .bookmark_add_list .color {
    float:left;
    width:3px; height:20px;
    border-radius:1.5px;
    margin-right:5px;
}
#Mappage .bookmark_add_list .title{
    float:left;
    padding:2px 0;
    font-size:16px; font-weight:600; 
    color:#737373;
}


#Mappage .bookmark_add_list .checkbox{
    width:16px; height:16px;
    border-color:#ff2828;
}
#Mappage .bookmark_add_list .checkbox{display:none;}
#Mappage .bookmark_add_list .checkbox + label{
    position:absolute;
    right:10px; top:50%;
    width:16px; height:16px;
    transform:translate(0,-50%);
    box-sizing: border-box;
    display:inline-block;
    border:1px solid #ff2828;
    border-radius:3px;
    z-index:1;
}
#Mappage .bookmark_add_list .checkbox:checked + label::after{
    position:absolute;
    content:'';
    left:50%; top:50%;
    transform:translate(-50%,-50%) rotate(45deg);
    margin:2px 0 0 -3px;
    width:6px; height:2px;
    background:#ff2828;
    
}
#Mappage .bookmark_add_list .checkbox:checked + label::before{
    position:absolute;
    content:'';
    left:50%; top:50%;
    width:10px; height:2px;
    margin:0 0 0 2px;
    transform:translate(-50%,-50%) rotate(-45deg);
    background:#ff2828;    
}

#Mappage .bookmark_add_list .warningMessage{
    margin:20px 0;
}

#Mappage .memo_wrap {
    position:relative;
    margin-top:25px;
}

#Mappage .memo_wrap .title{
    font-size:16px; font-weight:600;
    color:#737373;
}

#Mappage .memo_con{
    box-shadow: inset 0.143rem 0.143rem 0.286rem #babebc, inset -0.357rem -0.357rem 0.857rem #fff;
    width:100%; height:68px;
    margin-top:10px;
    border-radius: 0.714rem;
    
}

#Mappage .memo{
    width:85%; height:100%;
    border-radius: 0.714rem;
    padding: 10px;
    resize:none;
}
#Mappage .memo:focus{
    outline:none;
}
#Mappage .memo_wrap .text_counter{
    top:35px;
    transform:translateY(0);
}

#Mappage .bookmark_window .submit_btn {
    display: block; 
    width: 100%; height: 34px;
    margin-top:25px;
    border-radius: 10px; text-align: center; line-height: 34px;
    color: #fff; background-color: #434343;
    cursor:pointer;
 
    box-shadow:  2px 2px 4px #babebc;
}

#Mappage .bookmark_window .submit_btn.disabled  {
    cursor:auto;
    pointer-events:none;
    background:#666;
    
}

#Mappage .bookmark_window .submit_btn:active {
    box-shadow: inset 2px 2px 4px #babebc, inset -5px -5px 12px #fff;
    background-color: #fff; color: #222;
}
#Mappage .color_wrap{
    padding:25px 0;    
    border-bottom:1px solid rgba(211,211,211,.8);
}
#Mappage .color_wrap .title{
    font-size:14px; font-weight:600;
    color:#434343;
}
#Mappage .color_ul{
    margin-top:10px;
}
#Mappage .color_ul .color{
    float:left;
    width:20px; height:20px;
    background:#f0f;
    margin-right:15px;
    border-radius:50%;
    cursor:pointer;
}
#Mappage .color_ul .color.checked{
    border:1px solid #000;
    box-shadow:3px 3px 6px rgba(0,0,0,.25);
}
.clearfixed:after{
    display:block;
    clear:both;
    content:'';
}
#Mappage .subcategory_window .content_wrap,
#Mappage .bookmark_window .content_wrap{
    height:334px;
    padding-right:10px;
    overflow-y:scroll;
}

#Mappage .subcategory_window .content_wrap::-webkit-scrollbar,
#Mappage .bookmark_window .content_wrap::-webkit-scrollbar {
    width: 4px;
}
#Mappage .subcategory_window .content_wrap::-webkit-scrollbar:after,
#Mappage .bookmark_window .content_wrap::-webkit-scrollbar:after{
    
}
#Mappage .subcategory_window .content_wrap::-webkit-scrollbar-thumb ,
#Mappage .bookmark_window .content_wrap::-webkit-scrollbar-thumb {

    background-color: #dddddd; border-radius: 2px;
}
#Mappage .subcategory_window .content_wrap::-webkit-scrollbar-track ,
#Mappage .bookmark_window .content_wrap::-webkit-scrollbar-track {   
} 
#Mappage .bookmark_window .content_wrap .submit_btn{
    margin-bottom:5px;
}

#Mappage .warningMessage{
    text-align:center;
    font-size:16px; font-weight:600;
    margin-top:20px;
    
}

/*------------------------------------------------------------건축용도 가능여부------------------------------------------------------------*/
#Mappage .subcategory_window{
}
#Mappage .subcategory_window .content_wrap{
    height:290px;
}
#Mappage .subcategory_window .list_add_wrap{
    border-bottom:1px solid #737373;
}
#Mappage .more_btn{
    position:relative;
    display:inline-block;
    margin:-4px 0 0 6px;
    padding-left:6px;
    font-size:12px; font-weight:600;
    width: 70px; height:24px;
    line-height: 24px;
    border-radius: 5px;
    box-shadow: 0px 1px 3px rgba(0,0,0,.5);
    background:#f7f7f7;
    color:#434343;
    cursor:pointer;
    vertical-align:top;
}
#Mappage .more_btn:after,
#Mappage .more_btn:before{
    position: absolute;
    right:6px;top:11px;
    content:'';
    width:8px; height:2px;
    background:#737373;
    transition:all 250ms ease-out;
}
#Mappage .more_btn:before{
    transform:rotate(90deg);
}
#Mappage .more_btn.open:before{
    transform:rotate(-180deg);
}

#Mappage .sub_category_list{
    display:flex;
    justify-content:space-between;
    align-items:center;
    padding:10px 6px;
    border-bottom:1px solid #dfdfdf;
}
#Mappage .sub_category_list .title{
    width:140px;
    font-size:14px; font-weight:800;
    line-height:1.5em;
    word-break:normal;
}
#Mappage .sub_category_list .allowable{
    font-weight:800;
    color:#737373;
    
}
#Mappage .eum_notice{
    margin-top:10px;
    font-size:12px;
    font-family:'SUITE';
    letter-spacing:-0.04em;
    font-weight:500;
}
#Mappage .subcategory_window .btn_wrap{
    position: absolute;
    width:calc(100% - 40px); height:20px;
    right:20px; bottom:40px;
    border-bottom:1px solid #000;
}

#Mappage .btn_wrap .btn_b{
    bottom:-1px;
}


/*------------------------------------------------------------푸터------------------------------------------------------------*/


#Mappage .footer{text-align:center;padding:5px 60px; height:22px;}
#Mappage .footer div{font-size:12px;}
#Mappage .footer .left{float:left;}
#Mappage .footer .middle{position:absolute; left:50%; transform:translate(-50%,0);}
#Mappage .footer .middle a {
    font-size: 12px;
}
#Mappage .footer .right{float:right;}

#Mappage .modal{position:fixed; left:50%; bottom:0; padding:15px 100px; transform:translate(-50%,0); background:rgba(255,49,49,.8); transition:all 250ms ease-out;}
#Mappage .modal .modalMessage{font-size:16px; font-weight:500; color:#fff;}

#Mappage .infoWindow{position:relative; width:90px; height:124px; font-weight:600; padding:41px 0 0 ; text-align:center; z-index:1;}
#Mappage .infoWindow:after{position:absolute; left:0; bottom:-25px; content:''; width:90px; height:124px;background:url(../../public/images/map/image_infoWindow.svg) no-repeat; background-size:contain;z-index:-1;}
#Mappage .infoWindow .name{
    display: -webkit-box; /* Flex 컨테이너 */
    -webkit-box-orient: vertical; /* 박스 방향을 수직으로 설정 */
    -webkit-line-clamp: 2; /* 최대 두 줄로 제한 */
    overflow: hidden; /* 넘치는 텍스트를 숨김 */
    text-overflow: ellipsis; /* 생략 표시 추가 */
    white-space: normal; /* 줄바꿈 허용 */
    padding:0 5px; font-size:16px; font-weight:600; line-height:1.2em; word-break:normal; color:#9224B9;}
#Mappage .infoWindow .allow{margin-top:6px; font-size:14px; font-weight:600; color:#434343;}
#Mappage .infoWindow .estimateTotal{margin-top:8px; font-size:16px; font-weight:600;}

#Mappage .selectBox{margin-top:-4px;}
select{
    -webkit-appearance:none; /* for chrome */
 
    -moz-appearance:none; /*for firefox*/
 
    appearance:none;
    background:url(../../public/images/map/icon_select.svg) no-repeat 97% 50%/15px auto; background-color:#f7f7f7;
}



#Mappage .partner_banner{
    position:fixed;
    right:30px; bottom:60px;
    width: 140px; height:96px;
    line-height:40px;
    display:flex;
    justify-content: center;
    align-items:center;
    text-align: center;
    mask-image:url(../../public/images/houseBtnImage2.png);
    mask-size: cover;
    mask-position: center;background: 
    linear-gradient(90deg, rgba(86, 61, 241, 0.08), rgba(168, 23, 181, 0.08), rgba(162, 25, 183, 0.08), rgba(86, 61, 241, 0.08)),
    url(../../public/images/houseBtnImage3.png);
    backdrop-filter: blur(3px);
    /* box-shadow:2px 2px 4px rgba(0,0,0,.25); */
    z-index: 1;
}

#Mappage .partner_banner p{
    margin-top:20px;
    font-size: 16px; font-weight:900;
    text-align: center;
    vertical-align:middle;
    line-height:24px;
}

#Mappage .partner_banner img{
    box-shadow:2px 2px 4px rgba(0,0,0,.3);
}