.testBtnWrap {
    position:fixed;
    left:450px; top:80px;
    display:flex;
    gap:10px;
}

.testBtnWrap .btns{
    color:#fff;
    padding: 2px 5px;
    background:#222;
}