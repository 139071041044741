/* html, body {white-space:nowrap;} */ /* 반응형 시 글의 줄바꿈x */
/* main */
#Fullpage{overflow-y:hidden;
    position: fixed;
    width:100vw;}
#Fullpage .section_01{
    background-image: url(../../public/images/mainbnr.jpg); background-size: cover;
    background-position: bottom; height: 100vh;
    transition: background-image 250ms ease ;
    padding:0 !important;
}
#Fullpage .section_01 .width_con  h1 {
    white-space:nowrap;
    color: #fff; position: absolute; left: 50%; top: 32%;
    transform: translateX(-50%);
}

#Fullpage .width_con .wrap1_info {
    width: 100%;
    position: absolute; bottom: 3%;left: 0;
    display: flex; flex-flow: row nowrap; justify-content: space-between;
}
#Fullpage .width_con .wrap1_info li {
    position: relative;
    height: 50px; width: 32%; 
    display: inline-block; 
    outline-style: 5px solid #fff;
}
#Fullpage .width_con .wrap1_info li .desc{
    background-color: rgba(0,0,0,0.5);
    position: absolute;
    left: 0;
    box-shadow: 0 2px #fff;
    right: 0;
    bottom: 0;
    height: 50px;
    overflow: hidden;
    transition: height 250ms ease, box-shadow 250ms ease;
}
#Fullpage .width_con .wrap1_info li .desc::after{ content: ''; position: absolute; right: -15px; top:-15px;
    width:0; height:0;  transform:rotate(45deg); transition:all 250ms ease-out; background:#fff;
}
#Fullpage .width_con .wrap1_info li .desc:hover::after{width:30px; height:30px;}
#Fullpage .width_con .wrap1_info li .desc h3 {
    margin-bottom: 30px;;
    text-align: center;
    line-height: 50px;
}
#Fullpage .width_con .wrap1_info li .desc:hover h3 {
    font-size: 25px; text-align: left; 
} 
#Fullpage .width_con .wrap1_info li .desc:hover {
    height: 200px;
    box-shadow: 0 28px #fff;
    padding: 20px;
} 
#Fullpage .width_con .wrap1_info li h4 {line-height: 1.6; font-size: 18px;}
#Fullpage .width_con .wrap1_info li h3,
#Fullpage .width_con .wrap1_info li h4 {color: #fff;}
#Fullpage .width_con .wrap1_info li h4 {white-space:break-spaces;}
/* 클래스 추가 */
#Fullpage .change_bg1 {background-image: url(../../public/images/changebg1.jpg )  !important;}
#Fullpage .change_bg2 {background-image: url(../../public/images/changebg2.jpg )  !important;}
#Fullpage .change_bg3 {background-image: url(../../public/images/changebg3.jpg )  !important;}
/*----------------------------------section01-----------------------------------------------------------------------------------------------------*/

#Fullpage .sections{width: 100%; height: 100vh; position: relative; overflow: hidden;}
#Fullpage .width_con{position:relative; width:1440px; height:100%; margin:0 auto;}


#Fullpage .box_con{position:absolute; right:0; bottom:43px; width:464px; height:623px; padding:58px 25px;  border-top:solid 1px #000; background:#fff; text-align:left;}
#Fullpage .row{width:68px; height:1px; margin:30px 0; background:#000;}
#Fullpage .btn_a{position:absolute; bottom:58px; line-height:35px;}

#Fullpage .section_02{}
#Fullpage .section_02 .width_con{}
#Fullpage .section_02 .width_con .title{text-align:center;}
#Fullpage .section_02 .width_con .row{width:100%; margin:15px 0;}
#Fullpage .section_02 .width_con .desc{text-align:center; height: 50px;}
#Fullpage .section_02 .width_con .desc h2{line-height:1.5em;}
#Fullpage .section_02 .width_con .left{text-align:left;}
#Fullpage .section_02 .width_con .three_con{position:absolute; left:0; top:60%; transform:translateY(-50%);}
#Fullpage .section_02 .width_con .three_con li{}
#Fullpage .section_02 .width_con .three_con li .image{margin-top:60px; width:100%;background:#eee; transition:all 250ms ease-out;}
#Fullpage .section_02 .width_con .three_con li .image img{width:100%;}
#Fullpage .section_02 .width_con .three_con li:nth-child(1) .image{background-image: url(../../public/images/section_02/image_01_black.jpg); background-size: cover;}
#Fullpage .section_02 .width_con .three_con li:nth-child(2) .image{background-image: url(../../public/images/section_02/image_02_black.jpg); background-size: cover;}
#Fullpage .section_02 .width_con .three_con li:nth-child(3) .image{background-image: url(../../public/images/section_02/image_03_black.jpg); background-size: cover;}
#Fullpage .section_02 .width_con .three_con li:nth-child(1):hover .image{background-image: url(../../public/images/section_02/image_01_color.jpg); background-size: cover;}
#Fullpage .section_02 .width_con .three_con li:nth-child(2):hover .image{background-image: url(../../public/images/section_02/image_02_color.jpg); background-size: cover;}
#Fullpage .section_02 .width_con .three_con li:nth-child(3):hover .image{background-image: url(../../public/images/section_02/image_03_color.jpg); background-size: cover;}

#Fullpage .section_03{}
#Fullpage .section_03 .map{position:absolute; bottom:23px; transition:all 250ms ease-out;}
#Fullpage .section_03 .width_con{text-align:left;}
#Fullpage .section_03 .width_con .box_con{}
#Fullpage .section_03 .width_con .row{width:68px; margin:30px 0;}
#Fullpage .section_03 .width_con .btn_a{position:absolute; bottom:58px; line-height:35px;}

#Fullpage .section_04{}
#Fullpage .section_04 .width_con{z-index:1;}
#Fullpage .section_04 .width_con .box_con{right:auto; left:0; background:rgba(255,255,255,.5);}
#Fullpage .section_04 .image_box{position:absolute; right:4%; top:50%; width:60%; transform:translate(0,-50%);}
#Fullpage .section_04 .image_box img{width:100%;}

#Fullpage .section_05{background-image:url(../../public/images/section_05/image_01.png); background-position-y:bottom; background-repeat:no-repeat; background-size:contain; background-color:#fff; }
/*----------------------------------section01-----------------------------------------------------------------------------------------------------*/
#Fullpage .section_06{padding:0;}
#Fullpage .section_06 .left_line{position:absolute; left:10%; top:0; width:1px; height:100%; background:#000;}
#Fullpage .section_06 .image{position:absolute;width:100%; height:100%; background:url(../../public/images/section_06/image_01.png); background-size:contain; background-repeat:no-repeat; 
    pointer-events:none; z-index:1; }
#Fullpage .section_06 .propmate_con{position:absolute; width:100%; height:100%; 
    -webkit-mask-image: url("../../public/images/section_06/image_01.png");
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-size: 100%;
    z-index:2;
    pointer-events:none;
} 
#Fullpage .section_06 .propmate_con .propmate{position:absolute; left:54%; top:46.3%; width:100%; transform:translateX(-50%);}
#Fullpage .section_06 .width_con{width:100%; height:100%; margin-left:194px;}
#Fullpage .section_06 .bottom_line{position:absolute; bottom:25px; width:100%; height:1px; background:#000;}
#Fullpage .section_06 .top_con {position:relative; width:100%; height:47%; z-index:1;}
#Fullpage .section_06 .top_con .text_con{position:absolute; left:50%; top:50%; transform:translate(-50%,-50%); white-space:nowrap;}
#Fullpage .section_06 .top_con .text_con div{display: inline-block; margin:0 100px;}
#Fullpage .section_06 .top_con .text_con .mail{}
#Fullpage .section_06 .top_con .text_con div span{display: block; text-align: center;}
#Fullpage .section_06 .top_con .text_con div span:nth-child(1){font-size:40px; font-weight:700;  font-family:'Noto Serif' !important;}
#Fullpage .section_06 .top_con .text_con div span:nth-child(2){margin-top:10px;}
#Fullpage .section_06 .top_con .text_con .ir{}
#Fullpage .section_06 .bottom_con {position:relative; width:100%; height:53%; padding-bottom:25px; border-top:solid 1px #000;}
#Fullpage .section_06 .bottom_con .one_box{position:relative; width:10%; height:100%; float:left;}
#Fullpage .section_06 .bottom_con .two_box{position:relative; width:43.5%; height:100%; padding:10px 20px; border-right:solid 1px #000; float:left;}
#Fullpage .section_06 .bottom_con .three_box{position:relative; width:46.5%; height:100%; float:left;} 
#Fullpage .section_06 .bottom_con .three_box .left.bottom{left:12px;}
#Fullpage .section_06 .text_align_right {text-align:right;}
#Fullpage .section_06 .left.top,
#Fullpage .section_06 .left.bottom,
#Fullpage .section_06 .right.top,
#Fullpage .section_06 .right.bottom{position:absolute;}
#Fullpage .section_06 .left.top{left:20px; top:20px;}
#Fullpage .section_06 .left.bottom{left:20px; bottom:20px;}
#Fullpage .section_06 .right.top{right:20px; top:20px;}
#Fullpage .section_06 .right.bottom{right:20px; bottom:20px;}

/*-----------------------------------------------------fullpageHeader-----------------------------------------------------*/
#fullpage {position:fixed;}
#Fullpage header h1 {top:auto; bottom:0; opacity:0; pointer-events:none; transition: 250ms ease-out; transition-delay:250ms;}

#Fullpage.white header h1 {
    opacity:1; 
    pointer-events:auto; 
    transition: 250ms ease-out; 
    transition-delay:50ms;
}
/* header menu */
#Fullpage header .side_nav_con{
    position:fixed;
    top:50%;
    left:36px;
    transform:translateY(-50%);
    z-index:9999;
    transition:opacity 250ms ease-out; 
}
#Fullpage header .side_nav_con.hide{pointer-events:auto;}
#Fullpage header .side_nav_con.hide:hover{opacity:1;pointer-events:auto;}
#Fullpage header .side_nav_con .background{
    position: absolute;
    left:0;
    top:0;
    z-index:-1;
}
#Fullpage header .side_nav_con .cube{
    position:absolute;
    left:0;
    top:0;
    transition:all 500ms ease-out;
    transform:translateY(-25%);
}
#Fullpage header .side_nav_con .dot{
    position:relative;
    width:20px;
    height:14px;
    margin:16px 0;
    cursor:pointer;
}
#Fullpage header .side_nav_con .dot:nth-child(1){margin-top:0;}
#Fullpage header .side_nav_con .rowLine{position:absolute; left:0; top:36%; transform:translate(0,-50%); width:100px; height:4px; border-radius:2px; background:#F2EDED; transition:all 250ms ease-out;}


#Fullpage .header_bg {
    position: fixed; z-index: 9; width: 100%;
}
#Fullpage.white .burger-icon .burger-sticks:before,
#Fullpage.white .burger-icon .burger-sticks:after{
    background: #fff;
}
.header_bg .btn_map{
    position:absolute;
    right:0; bottom:0;
    width:40px; height:40px;
    border-radius:20px;
    background:#fff;
    text-align: center;
}
.header_bg .btn_map img{
    line-height: 40px;
    padding: 9px 0;
    height: 100%;
}
.header_bg .hover_box{
    position:absolute;
    right:0; top:0;
    font-size:16px; font-weight: 700;
    width:100%; height:100%;
    box-sizing: border-box;
    background:transparent;
    border-radius:inherit;
    z-index:-1;
    overflow:hidden;
    padding:12px 0;
    transition:all 250ms ease-out;
    white-space: nowrap;
    color:transparent;
}
.header_bg .btn_map.hover .hover_box,
.header_bg .btn_map:hover .hover_box{
    width:160px;
    padding:12px 25px 12px 0;
    color:#000;
}
#Fullpage.white .hover_box{
    background:#eee;
}
/*------------------------------------------컨설팅------------------------------------*/

#Fullpage .consulting_con{
    position:absolute;
    z-index:1;
}

#Fullpage .consulting_con > form{
    height:100%;
}

#Fullpage .consulting_background{
    position:absolute;
    left:0; top:0;
    width:100%; height:100%;
}