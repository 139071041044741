#MloginPage {
    margin: 0 5%;
}
#MloginPage input::placeholder {
    color: #bbb; font-size: 0.9rem;
}
#MloginPage .join_box > li, #MloginPage .login_box {margin-bottom: 20px;}
#MloginPage .login_warning_txt ,#MloginPage p > .warning_txt, #MloginPage .join_warning_txt, #MloginPage .find_pw_warning_txt{
    font-size: 12px; color: #ff0000;
}
#MloginPage .form_container > li {display: none;}
#MloginPage .form_container > li.display_y {display: block;}
#MloginPage .input_box_shadow,#MloginPage  select {
    box-shadow: inset 0.143rem 0.143rem 0.286rem #babebc, inset -5px -5px 0.857rem #fff;
    border-radius: 0.714rem; text-align: center;
}
#MloginPage .edit_select select {
    width: 5.714rem;
    margin: 0 0.357rem;
}
/* 경고문구 */
#MloginPage .login_warning_txt {}
#MloginPage .login_wrap > li:nth-child(3) {margin-bottom:1.429rem;}
/* 텍스트 스타일 */
#MloginPage input[type=text], #MloginPage input[type=password], #MloginPage input[type=number], #MloginPage .join_select select, #MloginPage .join_phone_empty {
    height: 2.857rem;
    width: 100%;
    padding: 0 1.071rem;
}
#MloginPage .join_occu_select {
    width: 50%;
}
#MloginPage .join_select *, #MloginPage .join_occu_select select{
    width: 100%;
    border-radius: 0.714rem;
    height: 2.857rem;
    padding: 0 !important;
    text-align: center;
}
#MloginPage .txt_box {margin-bottom: 0.357rem; padding: 0 2%; font-weight: 700;}
/* 배너 */
#MloginPage .warning_txt{
    font-size:12px; 
    padding:0 2%;
    color:#ff0000;
}
#MloginPage .m_login_bnr {
    text-align: center; padding: 15% 5%;
}
#MloginPage .m_login_bnr h2 {font-size: 2rem; margin-bottom: 7%;}
#MloginPage .m_login_bnr p {font-size: 1.2rem; line-height: 1.5rem;}
/* 리스트 탭 */
#MloginPage .tab_list {
    display: flex; flex-flow: row nowrap; justify-content: center;
    align-items: center; border: 1px solid #eee;
}
#MloginPage .tab_list li {text-align: center; width: 100%;}
#MloginPage .tab_list li button {
    height: 2.857rem; width: 100%;
}
#MloginPage .tab_list li button:hover {background-color: #eee;}
/* form 컨테이너 */
#MloginPage .form_container {
}
#MloginPage .form_container > li {}
/* 로그인 */
#MloginPage #login_container {
}
#MloginPage #login_container .login_wrap {
    padding: 10% 5%;
}
#MloginPage #login_container .login_wrap .login_box {
    display: flex; flex-flow: column nowrap;
    margin-bottom:1.429rem;
}
#MloginPage #login_container .login_wrap .login_box .txt_box {
    display: flex; justify-content: space-between;
    width: 100%;
}
#MloginPage #login_container .login_wrap .login_box .txt_box span {
    font-weight: 700;
}
#MloginPage #login_container .login_wrap .login_box .txt_box a {}
#MloginPage #login_container .login_wrap .login_box .login_id_empty {}
/* 로그인 버튼 */
#MloginPage .login_btn_box {
    display: flex; flex-flow: row nowrap; justify-content: center;
    align-items: start;
    margin: 0 auto;
}
#MloginPage .login_btn_box > li {}
#MloginPage .login_btn_box > li a {}
#MloginPage .login_btn_box > li a img {display: block;}
#MloginPage .kakao_btn {
    border-top-left-radius: 0.714rem; border-bottom-left-radius: 0.714rem; overflow: hidden;
    box-shadow: 0.143rem 0.143rem 0.286rem #babebc;
}
#MloginPage .naver_btn {
    margin: 0 0.357rem;
    box-shadow: 0.143rem 0.143rem 0.286rem #babebc;
}
#MloginPage .login_btn {
    border-top-right-radius: 0.714rem; border-bottom-right-radius: 0.714rem;
    box-shadow: 0.143rem 0.143rem 0.286rem #babebc;background-color: #434343;
}
#MloginPage .login_btn:active {
    box-shadow: inset0.143rem 0.143rem 0.286rem #babebc, inset -0.357rem -0.357rem 0.857rem #fff;
    background-color: #fff;
}
#MloginPage .login_btn a {
    display: block; color: #fff;
    width:8.571rem; line-height: 2.429rem; text-align: center;
}
#MloginPage .kakao_btn:active, #MloginPage .naver_btn:active {box-shadow: none;}
/* 회원가입 */
#MloginPage #join_wrap {}
#MloginPage .join_box {padding: 10% 5%;}
#MloginPage .join_box > li {margin-bottom:1.429rem;}
#MloginPage .join_box > li > p {}
/* 성별 radio 수정 */
#MloginPage input[type=radio]{
    display: none;
}
#MloginPage input[type=radio]+label {
    box-shadow: 0.143rem 0.143rem 0.286rem #babebc;
    border-radius:0.429rem;
    display: inline-block;
    cursor: pointer;
    height: 1.714rem;
    width: 6.429rem;
    line-height: 1.714rem;
    text-align: center;
    font-weight:bold;
    font-size:0.929rem;
}
#MloginPage input[type=radio]:checked+label{
    background-color: #434343;
    box-shadow: none;
    color: #fff;
}
/* radio / select */
#MloginPage .select_form {
    display: flex ; flex-flow: row nowrap; justify-content: space-between;
    align-items: center; padding-left: 0.357rem;
}
#MloginPage .select_form > p > span {font-size: 0.714rem; margin-left: 0.357rem; color: #bbb;}
#MloginPage .join_select {
    display: flex;
}
#MloginPage .birth {
    width: 50%;
}
/* 아이디 찾기 */
#MloginPage .txt_box a {
    font-size: 10px; color: #bbb;
}
#MloginPage .find_box {margin-bottom: 1.429rem;}
#MloginPage h3 {
    font-size: 20px; font-weight: 700; text-align: center;
    margin-bottom: 2rem;
}
#MloginPage .find_box input[type=number] {
    box-shadow: inset0.143rem 0.143rem 0.286rem #babebc, inset -0.357rem -0.357rem 0.857rem #fff;
    border-radius: 0.714rem;
    height: 2.857rem;
    padding: 0 1.071rem;
}
#MloginPage .find_box > li {
    margin-bottom:1.429rem;
}
#MloginPage .find_box > li:last-child {margin-bottom: 0;}
#MloginPage #find_id fieldset, #MloginPage #find_pw fieldset {
    height: 100%; 
    padding: 10% 5%;
    display: flex; flex-flow: column nowrap; justify-content: space-between;
}
#MloginPage .find_box li p {
    position: relative;
}
#MloginPage .find_box button[type=submit] {
    background-color: #434343;
    box-shadow:0.143rem 0.143rem 0.286rem #babebc;
    color: #fff;
    border-radius: 0 0.714rem 0.714rem 0;
    position: absolute; right: 0; top: 0;
    width: 5rem; height: 100%;
}
/* 버튼 */
#MloginPage .join_submit_btn, #MloginPage .find_id_btn, #MloginPage .find_pw_btn {
    display: block;  border-radius: 0.714rem; text-align: center; line-height: 2.429rem;
    color: #fff; background-color: #434343; width: 260px; height: 2.429rem;
    box-shadow: 0.143rem 0.143rem 0.286rem #babebc;
}
#MloginPage .join_submit_btn:active, #MloginPage .find_id_btn:active, #MloginPage .find_pw_btn:active {
    box-shadow: inset0.143rem 0.143rem 0.286rem #babebc, inset -0.357rem -0.357rem 0.857rem #fff;
    background-color: #fff; color: #222;
}
/* 이용약관 동의 */
#MloginPage #join_wrap fieldset .agree_wrap > p {
    border-bottom: 0.071rem solid #222; padding: 0.357rem 0;
}
#MloginPage #join_wrap fieldset .agree_wrap > p input[name=agree_all] {}
#MloginPage #join_wrap fieldset .agree_wrap > p label {
    font-size: 1rem;
}
#MloginPage #join_wrap fieldset .agree_wrap .agree_select {
    margin-bottom: 1rem;
}
#MloginPage #join_wrap fieldset .agree_wrap .agree_select li {
    padding:0.4rem 0;
    display: flex; flex-flow: row nowrap; 
}
#MloginPage #join_wrap fieldset .agree_wrap .agree_select li .clause {
    width: 100%;
}
#MloginPage #join_wrap fieldset .agree_wrap .agree_select li > p {}
#MloginPage #join_wrap fieldset .agree_wrap .agree_select li label {}
#MloginPage #join_wrap fieldset .agree_wrap .agree_select li label span {
    font-size: 0.714rem;color: #757575; margin-left: 0.357rem;
}
#MloginPage #join_wrap fieldset .agree_wrap .agree_select li a {
    font-size: 0.857rem;color: #757575;
}
#MloginPage .join_submit, #MloginPage .find_id, #MloginPage .find_pw {display: flex; justify-content: center;}

#MloginPage .txt_box_con{
    display:flex;
    justify-content: space-between;
}

@media screen and (max-width:768px) {
    #MloginPage .birth {
        width: 80%;
    }
}