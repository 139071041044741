html.ovhi,
body.ovhi{position:relative;overflow:hidden!important;touch-action:none;}
#Viewer Header {
    position: absolute;
    z-index: 99;
    width: 90%;
    max-width: none;
    margin: 0 5%;
}
#Viewer .burger-icon .burger-sticks:before,#Viewer .burger-icon .burger-sticks:after {
    background-color: #fff;
}
#Viewer .burger-icon {}
#Viewer header h1 a img {
    content: url(../../public/images/logoWhite.png);
}
#Viewer {}
#Viewer main .viewList {
    height: calc(var(--vh, 1vh) * 100); 
    display: flex;
    justify-content: space-between;
    overflow:hidden;
}
#Viewer main .viewList li {
    position: relative;
    width: 20%;
    transition: all 250ms linear;
    box-shadow:-2px 4px 20px rgba(0,0,0,.5);
}
#Viewer main .viewList li .href{
    display:block;
    width:100%;
    height:100%;
}
#Viewer main .viewList li .img {
    display:block;
    height: 100%;
    background-size: cover;
    filter: brightness(50%) grayscale(100%);
    transition:all 250ms linear;
}
#Viewer main .viewList li:nth-child(1) .img {
    background-image: url(../../public/images/3Dviewer/3D_first.jpg);
}
#Viewer main .viewList li:nth-child(2) .img {
    background-image: url(../../public/images/3Dviewer/3D_second.jpg);
}
#Viewer main .viewList li:nth-child(3) .img {
    background-image: url(../../public/images/3Dviewer/3D_third.jpg);
}
#Viewer main .viewList li:nth-child(4) .img {
    background-image: url(../../public/images/3Dviewer/3D_fourth.jpg);
}
#Viewer main .viewList li:nth-child(5) .img {
    background-image: url(../../public/images/3Dviewer/3D_fifth.jpg);
}
#Viewer main .viewList li .text_wrap {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    padding: 150px 5% 5%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: end;
    align-items: start;
}
#Viewer main .viewList li .text_wrap > p {
    position: absolute;
    bottom: 40px;
    left: 40px;
    font-weight: 700;
    font-size: 3rem;
    color: #fff;
    writing-mode: vertical-rl;
    transition:all 250ms ease;
}
#Viewer main .viewList li .text_wrap .txt_box {
    opacity:0;
    pointer-events: none;
    transition:all 500ms ease;
}
#Viewer main .viewList li .text_wrap .txt_box > * {
    color: #fff;
}
#Viewer main .viewList li .text_wrap .txt_box p {
    font-size: 3rem;
    margin-bottom: 10%;
    font-weight: 700;
    white-space:nowrap;
}
#Viewer main .viewList li .text_wrap .txt_box a {
    font-size: 1.1rem;
    position: relative;
}
#Viewer main .viewList li .text_wrap .txt_box a img {
    position: absolute;
    bottom: -20%;
    left: 0;
}

#Viewer main .viewList li.hover .img {
    filter: none;
}

#Viewer main .viewList li.hover{
    width:28%;
    box-shadow:10px 0 20px rgba(0,0,0,.5),-10px 0 20px rgba(0,0,0,.5);
    z-index:1;
}

#Viewer main .viewList li.hover .text_wrap .txt_box{
    opacity:1;
    pointer-events: auto;
}

#Viewer main .viewList li.hover .main_title{
    opacity:0;
}