/* tablet */
@media screen and (max-width:1024px){
    
    .footer_info, .footer_link {line-height: 1.5;}
    #Company .text_wrap h2 {display: none;}
    #Company .mobile_title {
        display: block;
        font-size: 2rem;
        font-weight: 700;
        margin-bottom: 3%;
    }
    #Company main {margin: 20% 0 10%;}
    #Company .container {flex-flow: column nowrap; max-height: none; width: auto;}
    #Company .container::after {display: none;}
    #Company .image_container {margin: 0 0 20px 0;}
    #Company .information_container {margin: 0;}
    #Company .download > p {margin: 2% 0}
    #Company .download > p {margin: 5% 0}

}
/* mobile */
@media screen and (max-width:768px){

    #Company .mobile_title {
        font-size: 1.5rem;
    }
    .menu {top: 2.5rem;}
    header { height:  3.929rem !important;}
    header h1 a {width: 100px;}
    header h1 a img {height: 3.214rem}
    .burger-icon .burger-sticks {width: 1.429rem;}
    .burger-icon .burger-sticks:after {top: -0.357rem;}
    footer * {font-size: 0.7rem !important;}
    .menu .menu_contents {width: 7.857rem;}
    body {font-size: 12px !important;}
    #Company {margin: 0 5%;}
    #Company .text_wrap h2 {font-size: 1.5rem;}
    #Company .file_opacity h3 {font-size: 1rem;}
    #Company .file_opacity p {font-size: 0.8rem;}
    #Company .download {padding: 5% 0;}

}

@media screen and (max-width:550px){

    #Company .information_container {width: 100%;}

}